import { getConfig } from "../../config";
import { conformToMask } from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import _ from 'lodash';


export const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 9, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const calcMaskOptions = (countryDetail) => {
  if (countryDetail?.currency || countryDetail?.code) {
    let userLang = navigator?.language || navigator?.userLanguage;
    let lang = userLang?.split('-')[0];
    const options = { style: 'currency', currency: countryDetail.currency || countryDetail.code };
    const numberFormat = new Intl.NumberFormat(lang+'-'+countryDetail.iso2, options);
    const parts = numberFormat.formatToParts(1234.5);
    let thousands = _.find(parts, ({ type }) => type === 'group');
    thousands = thousands?.value;
    let decimal = _.find(parts, ({ type }) => type === 'decimal');
    decimal = decimal?.value;
    let fraction = _.find(parts, ({ type }) => type === 'fraction');
    fraction = fraction?.value ? parseInt(fraction?.value) : false;

    return {
      includeThousandsSeparator: !!thousands,
      thousandsSeparatorSymbol: thousands || null,
      allowDecimal: !!decimal,
      decimalSymbol: decimal || null,
      decimalLimit: fraction || null,
      requireDecimal: !!fraction
    };
  }
};

// pasa cadena con formato a number, según las opciones de máscara
export const getRawValue = (value = 0, maskOptions, doRound) => {
  const {
    includeThousandsSeparator,
    thousandsSeparatorSymbol,
    allowDecimal,
    decimalSymbol,
    decimalLimit,
    requireDecimal
  } = maskOptions;

  let rawStringValue = value;

  if (_.isNumber(value)) {
    rawStringValue = value.toString();
    if (doRound) {
      rawStringValue = Math.round(value, decimalLimit).toString();
    }
  }

  if (includeThousandsSeparator) {
    const regex = new RegExp(`\\${thousandsSeparatorSymbol}`, 'g');
    rawStringValue = rawStringValue.replace(regex, '');
  }

  if (allowDecimal) {
    const regex = new RegExp(`\\${decimalSymbol}`, 'g');
    rawStringValue = rawStringValue.replace(regex, '.');

    if (requireDecimal && rawStringValue.indexOf('.') === -1) {
      // Agregar decimal si es necesario y no está presente
      rawStringValue += '.00';
    }
  }

  return parseFloat(rawStringValue);
};

export const toAmount = (numQty = 0, countryDetail) => {
  countryDetail = countryDetail || getConfig().country;
  console.log('countryDetail', countryDetail)
  return {
    qty: toAmountString(numQty, countryDetail),
    rawQty: numQty,
    country: countryDetail
  }
}

export const toAmountString = (numQty = 0, countryDetail) => {
  let maskOptions = _.defaults(calcMaskOptions(countryDetail || getConfig().country), defaultMaskOptions);
  numQty = numQty || 0;
  const rawQty = getRawValue(numQty, maskOptions, !maskOptions.requireDecimal)
  const currencyMask = createNumberMask(maskOptions);
  const maskedValue = conformToMask(rawQty.toString(), currencyMask, { guide: false }).conformedValue
  return maskedValue;
}

export const getAmountNum = (value = 0) => {
  if (_.isNumber(value)) {
    return value;
  }
  if (_.isEmpty(value)) {
    return 0;
  }
  return Reflect.get(value, 'rawQty');
};

export const sumValues = (...values) => {
  return values.reduce((sum, value) => sum + getAmountNum(value), 0);
};
  
export const subtractAmountToNum = (amount1, amount2) => {
    return getAmountNum(amount1) - getAmountNum(amount2);
}

export const ShowAmount = ({amount, classes}) => {
  if (!amount) {
    return null;
  }
  return (
    <div className="flex flex-row flex-nowrap items-baseline gap-1">
      <span>{amount?.qty}</span>
      <span>{amount?.country?.symbol}</span>
      <span className={`react-tel-input text-xs flex flex-row flex-nowrap gap-0.5 items-baseline ${classes?.currencyFlag}`}>
        <div className={`flag ${amount?.country?.iso2?.toLowerCase()}`}></div>
        <span>{amount?.country?.currency || amount?.country?.code}</span>
      </span>
    </div>
  );
};