/**
 *
 * migration: 001-fieldsIds
 *
 * Se debe renombrar el taxonomy con nameSlug fieldsId por fieldsIds y
 * cambiar su tipo de campo por selección mutliple de documentos.
 *
 */


import { TaxonomyTypeModel } from '../Entity';

export default async function migrationInstance001({ instanceDoc, instanceSettings }) {
  let instanceHash = instanceDoc.data.hash;

  try {
    let taxonomyTypeDocs = await TaxonomyTypeModel.filterByAttributes({
      nameSlug: 'fieldsId',
      instanceId: instanceDoc.id
    });

    if (taxonomyTypeDocs?.length) {
      let taxonomyTypeDoc = taxonomyTypeDocs[0];
      // Rename nameSlug
      taxonomyTypeDoc.data.nameSlug = 'fieldsIds';
      // Change type
      taxonomyTypeDoc.data.type = 'selectManyEntityDocument';
      // save
      await taxonomyTypeDoc.save();
      console.log(`Migration 001-fieldsIds applied to entity: ${taxonomyTypeDocs[0]}`);
    }
    else {
      // console.warn(`Taxonomy 'fieldsId' not found in entity: ${taxonomyTypeDocs[0]}, skipping rename and type change.`);
    }

    return true;

  } catch (error) {
    console.error(`Error during migration 001-fieldsIds for instance: ${instanceHash}`, error);
    return false; // Indicate migration failure
  }
}