import _ from 'lodash';
import { getAmountNum, toAmount } from "../../components/Form/utilsCurrency";
import { BtnPrintInvoice } from "./BtnPrintInvoice";
import dayjs from 'dayjs';
import { getConfig } from '../../config';


let taxesDefinitions = [
  // { key: 'exempt', rate: 0 }, // Exempt taxes (if needed later)
  {
    key: 'vat5',
    rate: 1.05
  },
  {
    key: 'vat10',
    rate: 1.10
  }
];

export const afterRead = async (props, docData) => {
  if (!docData.id)  {
    docData.issuedDate = dayjs().toISOString();
  }
  return docData;
};

export const hooksOnFormChange = (props) => {
  let {
    doc, formValues, previousValues, form, instance, entitySlug, entityDoc, taxonomyTypesDocList
  } = props;

  ///////////////////////////
  //  When items change
  ///////////////////////////

  // Update totalPrice
  let newTotalPrice = _.sumBy(formValues?.items, (item) => getAmountNum(item.totalPrice));
  console.log('toAmount(newTotalPrice || 0)', toAmount(newTotalPrice || 0, getConfig().country))
  form.change('totalPrice', toAmount(newTotalPrice || 0, getConfig().country));

  // Update settlement taxes
  let taxesPrices = {};
  _.forEach(formValues?.items, (item) => {
    _.forEach(taxesDefinitions, ({ key, rate }) => {
      if (item.taxes?.[key]) {
        let totalPriceForTaxes = getAmountNum(item.taxes[key]);
        let taxableBase = totalPriceForTaxes / rate;
        let vat = totalPriceForTaxes - taxableBase;
        // Initialize key if not already present
        taxesPrices[key] = taxesPrices[key] || { taxableBase: 0, vat: 0 };
        // Accumulate values
        taxesPrices[key].taxableBase += taxableBase;
        taxesPrices[key].vat += vat;
      }
    });
  });
  // Update form values for taxes
  _.forEach(taxesDefinitions, ({ key }) => {
    const taxData = taxesPrices[key] || { taxableBase: 0, vat: 0 };
    form.change(`taxes.${key}`, toAmount(taxData.vat || 0));
  });
  // Update total VAT
  let totalVAT = _.sumBy(Object.values(taxesPrices), (data) => data.vat);
  form.change('totalTaxes', toAmount(totalVAT || 0));
};

export const FormToolbarEndInvoices = ({ doc, form, values, classes, handleSubmit, submitting, fieldsRequired, instance, entitySlug, isAllowed, history, match }) => (<>
  {/* <BtnPrintInvoice invoiceDoc={doc} entitySlug={entitySlug} isAllowed={isAllowed} color="primary" fill="clear" /> */}
</>);

export const ShowItemExtraActionsInvoices = ({ isAllowed, entitySlug, entityDoc, entityId, docId, doc, attachPrefix }) => {
  return (<>
    <BtnPrintInvoice invoiceDoc={doc} entitySlug={entitySlug} isAllowed={isAllowed} color="primary" fill="clear" />
  </>);
};

export const ListItemExtraActionsInvoices = ({ doc, Model, isAllowed, canAddMoreDocs, entitySlug, entityDoc, location, entitySpecs, moduleSettings, attachPrefix }) => (<>
  <BtnPrintInvoice invoiceDoc={doc} entitySlug={entitySlug} isAllowed={isAllowed} color="primary" fill="clear" />
</>);
