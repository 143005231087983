import _ from 'lodash';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { InstanceInlineSelectorForMain } from '../instance/InstanceSelector';
import { usePanel } from '../panel/usePanel';
import { useModule } from '../../libs/ModuleContext';
import SwitchInputB from '../../components/Form/SwitchInputB';
import BadgeLoading from '../../components/ui/BadgeLoading';
import ModalAlert from '../../components/ui/ModalAlert';
import Model from '../../libs/ModelClass';
import config from '../../config';
import { getJson } from '../../libs/utils';
import { getNewPrefix, withOutPrefix } from '../instance/utilsInstance';


const entitiesNameSlug = config.modules.entity.entitiesEntitySlug;
const EntityModel = Model.extend(entitiesNameSlug);
const taxonomyTypesNameSlug = config.modules.entity.taxonomyTypesEntitySlug;
const TaxonomyTypeModel = Model.extend(taxonomyTypesNameSlug);
const filterMenuNameSlug = config.modules.entity.filterMenuEntitySlug;
const FilterMenuModel = Model.extend(filterMenuNameSlug);
const rolesEntitySlug = config.modules.user.rolesEntitySlug;
const RoleModel = Model.extend(rolesEntitySlug);

const getUpdatedTaxonomyTypeId = (filterTaxonomyTypeId, taxonomyTypesDataList, newTaxonomyTypesDocs) => {
  let newTaxonomyTypeId = filterTaxonomyTypeId;
  if (filterTaxonomyTypeId) {
    let taxonomyTypeData = taxonomyTypesDataList.find(t => t.id === filterTaxonomyTypeId);
    if (taxonomyTypeData) {
      let newTaxonomyTypeDoc = newTaxonomyTypesDocs.find(t => withOutPrefix(t.data.nameSlug) === taxonomyTypeData.nameSlug);
      if (newTaxonomyTypeDoc) {
        newTaxonomyTypeId = newTaxonomyTypeDoc.id;
      }
    }
  }
  return newTaxonomyTypeId;
};

export const removeIds = (dataList) => {
  if (!_.isArray(dataList)) {
    Reflect.deleteProperty(dataList, 'id');
    return dataList;
  }
  return dataList?.map(item => {
    let { id, ...data } = item;
    return data;
  });
};

export const installModules = async ({ modulesSpecs, instanceDoc, settings, logger }) => {
  if (!instanceDoc) {
    throw new Error('instanceDoc is required');
  }
  if (!modulesSpecs) {
    throw new Error('modulesSpecs is required');
  }
  if (!settings) {
    throw new Error('settings is required');
  }
  let instanceHash = instanceDoc.data.hash;
  // add entities records from models
  // save entity main doc
  // save all taxonomyType docs
  // save all filterMenu docs
  let permissions = {};
  for (const module of modulesSpecs) {
    if (_.size(module.entities)) {
      // check if module is installed
      let moduleSettings = await settings.getRemote(instanceHash, `modules.${module.slug}`, {});
      if (moduleSettings.status === 'installed') {
        logger && await logger(`Módulo ${module.name} ya instalado`);
        return;
      }

      // install global modules for main instance only
      if (instanceHash !== 'main' && module.scope !== 'instance') {
        return;
      }
      let newEntitiesSettings = {};
      let entitiesSpec = _.isArray(module.entities)
        ? (await getJson(`/data/${module.slug}/entities.json`))
        : _.isObject(module.entities)
          ? module.entities
          : null;
      for (let [ entitySlug, { entity, filterMenuList, taxonomyTypesList } ] of Object.entries(entitiesSpec)) {
        let permissionsDef = module?.permissions.find(p => p.slug === entitySlug);
        // attach instanceHash as a prefix for entitySlug
        if (instanceHash === 'main' && module.scope === 'instance') {
          // attach instanceHash as a prefix for entitySlug
          entitySlug = `main.${entitySlug}`;
        }
        else if (instanceHash === 'main' && module.scope === 'global') {
          // use entitySlug as is
        }
        else if (instanceHash !== 'main' && module.scope === 'instance') {
          entitySlug = `${instanceHash}.${entitySlug}`;
        }

        logger && await logger(`Instalando ${entity.name}`);

        // clone entity
        let entityToUse = { ...entity };
        // update entity
        entityToUse.nameSlug = entitySlug;
        entityToUse.isInstance = module.scope === 'instance';
        entityToUse.module = module.slug;
        if (entityToUse.isInstance) {
          entityToUse.instanceId = instanceDoc.id;
        }
        // remove doc id
        delete entityToUse.id;

        // settings for entity
        newEntitiesSettings[entity.nameSlug] = {
          entitySlug
        };

        // assign permissions of instance
        permissions[entitySlug] = permissionsDef?.actions || [];

        // save entity main doc
        const entityDoc = await EntityModel.create(entityToUse);
        // const entityDoc = entityToUse; // for debug
        // console.log('entityDoc', entityDoc.id, entityDoc);
        const entityId = entityDoc.id;


        // save all taxonomyType docs
        let newTaxonomyTypesDocs = await TaxonomyTypeModel.createMany( removeIds(taxonomyTypesList) );
        // console.log('newTaxonomyTypesDocs', newTaxonomyTypesDocs);


        // assign entityId
        filterMenuList.forEach(filterMenuData => {
          filterMenuData.entityId = entityId;
          filterMenuData.instanceId = instanceDoc.id;
          // update filters ids
          filterMenuData.filters.forEach(filter => {
            filter.id = getUpdatedTaxonomyTypeId(filter.id, taxonomyTypesList, newTaxonomyTypesDocs);
          });
        });
        // save all filterMenu docs
        await FilterMenuModel.createMany( removeIds(filterMenuList) );
        // console.log('filterMenuList', filterMenuList);

        // save seed data
        let seedData = await getJson(`/data/${module.slug}/${withOutPrefix(entitySlug)}.json`);
        if (_.isArray(seedData)) {
          if (module.scope === 'instance') {
            seedData = seedData.map((data) => {
              data.instanceId = instanceDoc.id;
              return data;
            });
          }
          await Model.extend(entitySlug).createMany( removeIds(seedData) );
        }
      }
      // save setting
      await settings.setRemote(instanceHash, `modules.${module.slug}`, { status: 'installed', entities: newEntitiesSettings });
      // console.log(`modules.${instanceHash}.${module.slug}`, { status: 'installed', entities: newEntitiesSettings });
    }
  }

  logger && await logger(`Asignando permisos`);

  // add permissions to super-admin
  let superAdminSlug = instanceHash === 'main' ? 'super-admin' : `${instanceHash}.super-admin`;
  const roleDoc = await RoleModel.findOneBy('nameSlug', superAdminSlug);
  if (roleDoc) {
    roleDoc.data.permissions = {
      ...roleDoc.data.permissions,
      ...permissions
    };
    await roleDoc.save();
    // console.log('roleDoc', permissions)
  }
};

export function Content(props) {
  let {
    selectedInstance
  } = props;
  const { settings } = usePanel();
  const isSettingsLoaded = settings.loadScope(selectedInstance?.data.hash);
  const moduleLibs = useModule();
  const [ moduleToInstall, setModuleToInstall ] = useState();
  const instalableModules = useMemo(() => (
    moduleLibs.modules.filter(m => {
      if ( _.size(m.entities) > 0 ) {
        // mostrar a instancias sólo lo de instancia
        if (selectedInstance && selectedInstance.data.hash !== 'main') {
          return m.scope === 'instance';
        }
        // mostrar para main todos los modulos
        else {
          return true;
        }
      }
      return false;
    })
  ), [moduleLibs, selectedInstance]);

  const instanceHash = selectedInstance?.data.hash;
  const instanceName = selectedInstance ? `${selectedInstance?.data.name} [${instanceHash}]` : `[${instanceHash}]`;

  const handleInstallation = async () => {
    console.log(`INSTALLING MODULE ${moduleToInstall?.name} on INSTANCE ${instanceHash}: Starting`);
    try {
      await installModules({ modulesSpecs: [moduleToInstall], instanceHash, instanceDoc: selectedInstance, settings });
      setModuleToInstall(null);
      toast.success(`Módulo ${moduleToInstall?.name} instalado en la instancia: ${instanceName}`);
    } catch (error) {
      console.error(error);
      setModuleToInstall(null);
      toast.error(`Error al instalar el módulo ${moduleToInstall?.name} en la instancia ${instanceName}`);
    }
    console.log(`INSTALLING MODULE ${moduleToInstall?.name} on INSTANCE ${instanceHash}: Finished`);
  };
  
  const getIsModuleInstalled = (module) => {
    const moduleSettings = settings.get(instanceHash, `modules.${module.slug}`, {});
    return moduleSettings.status === 'installed';
  };

  const verifyBeforeInstall = (module) => () => {
    getIsModuleInstalled(module) 
      ? toast('El módulo ya está instalado', { icon: 'ℹ️' })
      : setModuleToInstall(module);
  };

  async function handleSyncTaxonomyTypes(module) {

    /*
    TODO
      en todo caso se debe sobreescribir cada taxonomy 
        (cuidando con los ids, se deben mantener los ids de la base de datos)
    */

    if (!selectedInstance) {
      toast.error('No hay instancia seleccionada');
      return;
    }

    const instanceHash = selectedInstance.data.hash;
    const instanceId = selectedInstance.id;

    try {
      toast.loading('Sincronizando tipos de taxonomía...', { id: 'syncTaxonomies' });

      // 1. Obtener tipos de taxonomía desde entities.json
      const entitiesSpec = await getJson(`/data/${module.slug}/entities.json`);

      // 2. Iterar sobre cada entidad en entitiesSpec
      for (const entityKey in entitiesSpec) {
        const entitySpec = entitiesSpec[entityKey];
        const taxonomyTypesListFromEntities = entitySpec?.taxonomyTypesList || [];
        if (!taxonomyTypesListFromEntities.length) continue;

        // 3. Obtener tipos de taxonomía desde la base de datos
        const entityNameSlug = instanceHash === 'main' && module.scope === 'instance'
          ? `main.${entityKey}`
          : instanceHash !== 'main' && module.scope === 'instance'
            ? `${instanceHash}.${entityKey}`
            : entityKey;
        const entitiesDocs = await EntityModel.filterByAttributes({ nameSlug: entityNameSlug, deleted: false });
        const entityDoc = entitiesDocs?.[0];
        if (!entityDoc) {
          throw new Error(`Entidad ${entityNameSlug} no encontrada`);
        }
        const existingTaxonomyTypes = await TaxonomyTypeModel.filterByAttributes({ entityId: entityDoc.id, deleted: false });
        console.log('existingTaxonomyTypes', existingTaxonomyTypes)

         // attach instanceHash as a prefix for each taxonomyType of type selectOneEntityDocument
        // assign entityId
        taxonomyTypesListFromEntities?.forEach(taxonomyTypeData => {
          if (
            taxonomyTypeData.type === "selectOneEntityDocument"
            || taxonomyTypeData.type === "selectManyEntityDocument"
          ) {
            taxonomyTypeData.param.entityNameSlug = getNewPrefix(taxonomyTypeData.param.entityNameSlug, selectedInstance, module);
          }
          taxonomyTypeData.entityId = entityDoc.id;
          taxonomyTypeData.instanceId = instanceId;
        });


        // 4. Comparar y identificar nuevos tipos de taxonomía
        const newTaxonomyTypes = [];
        for (const taxonomyTypeFromEntities of taxonomyTypesListFromEntities) {
          const existsInDb = existingTaxonomyTypes.find(
            (dbTaxonomyType) => dbTaxonomyType.data.nameSlug === taxonomyTypeFromEntities.nameSlug
          );
          if (!existsInDb) {
            newTaxonomyTypes.push(taxonomyTypeFromEntities);
          }
        }

        // 4. Crear nuevos tipos de taxonomía en la base de datos
        if (newTaxonomyTypes.length > 0) {
          const taxonomyTypesToCreate = newTaxonomyTypes.map(taxonomyType => ({
            ...taxonomyType,
            entityId: entityDoc.id,
            instanceId: instanceId,
          }));
          await TaxonomyTypeModel.createMany(removeIds(taxonomyTypesToCreate));
          toast.success(`Se agregaron ${newTaxonomyTypes.length} nuevos tipos de taxonomía`, { id: 'syncTaxonomies' });
        }

        // 5. Identificar y actualizar tipos de taxonomía existentes
        const modifiedTaxonomyTypes = [];
        for (const dbTaxonomyType of existingTaxonomyTypes) {

          const entityTaxonomyType = taxonomyTypesListFromEntities.find(
            (entityTaxonomyType) => entityTaxonomyType.nameSlug === dbTaxonomyType.data.nameSlug
          );

          if (entityTaxonomyType) {
            // 5. Reemplazar directamente los datos
            modifiedTaxonomyTypes.push({
              id: dbTaxonomyType.id,
              data: {
                ...entityTaxonomyType,
                entityId: entityDoc.id,
                instanceId: instanceId,
                id: dbTaxonomyType.id
              },
            });
          } else {
            // 6. Si no existe en entities.json, marcar como deleted
            dbTaxonomyType.data.deleted = true;
            await dbTaxonomyType.save();
          }
        }

        // 7. Guardar tipos de taxonomía modificados
        for (const modifiedTaxonomyType of modifiedTaxonomyTypes) {
          // find by nameSlug
          let taxonomyTypeDocs = await TaxonomyTypeModel.filterByAttributes({ nameSlug: modifiedTaxonomyType.data.nameSlug, deleted: false });

          if (taxonomyTypeDocs?.length > 1) {
            // handle duplicated: assign the most recent, delete the rest
            console.log('taxonomyTypeDocs duplicated', taxonomyTypeDocs)
            taxonomyTypeDocs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const latestDoc = taxonomyTypeDocs[0];

            for (const doc of taxonomyTypeDocs.slice(1)) {
              try {
                  doc.data.deleted = true;
                  await doc.save();
                  console.log('deleted', doc.data.nameSlug, doc.id, doc);
              } catch (error) {
                  console.error("Error al guardar el documento:", doc.id, error);
                  toast.error(`Error al guardar el documento ${doc.id}`);
              }
            }

            // update latest doc
            latestDoc.data = {
              ...latestDoc.data,
              ...modifiedTaxonomyType.data,
              id: latestDoc.id
            };
            console.log('taxonomyTypeDoc save', latestDoc, modifiedTaxonomyType)
            await TaxonomyTypeModel.createOrUpdate(latestDoc.data); // TODO aquí está el problema, 
          }
          else if (taxonomyTypeDocs?.length === 1) {
            // update
            taxonomyTypeDocs[0].data = {
              ...taxonomyTypeDocs[0].data,
              ...modifiedTaxonomyType.data
            };
            console.log('taxonomyTypeDoc update', taxonomyTypeDocs[0]);
            await TaxonomyTypeModel.createOrUpdate(taxonomyTypeDocs[0].data);
          }
          else {
            // create if not exists
            console.log('taxonomyTypeDoc create', modifiedTaxonomyType.data)
            await TaxonomyTypeModel.create(removeIds(modifiedTaxonomyType.data));
          }
        }


        if (newTaxonomyTypes.length === 0 && modifiedTaxonomyTypes.length === 0) {
          toast.success('No se encontraron tipos de taxonomía para sincronizar', { id: 'syncTaxonomies' });
        } else if (modifiedTaxonomyTypes.length > 0) {
          toast.success(`Se actualizaron ${modifiedTaxonomyTypes.length} tipos de taxonomía`, { id: 'syncTaxonomies' });
        }
      }
    } catch (error) {
      console.error('Error al sincronizar tipos de taxonomía', error);
      toast.error('Error al sincronizar tipos de taxonomía', { id: 'syncTaxonomies' });
    } finally {
      toast.dismiss({ id: 'syncTaxonomies' });
    }
  }

  if (!isSettingsLoaded) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }
    
  return (
    <div className="space-y-2">
      {instalableModules.map((module, index) => (
        <div key={index} className="border bordre-gray-200 rounded-md p-2 md:p-4">
          <h2 className="font-bold text-base">{module.name}</h2>
          <h3 className="font-mono text-sm text-gray-500">{module.slug}</h3>
          <h3 className="font-mono text-sm text-gray-500">[{module.scope}]</h3>
          <hr className="my-2 border-t border-gray-200" />
          <SwitchInputB
            value={getIsModuleInstalled(module)}
            onChange={verifyBeforeInstall(module)}
            textTrue="Instalado"
            textFalse="No instalado"
            colorTrue="blue-700"
          />
          {getIsModuleInstalled(module) ? (
            <button
              className="ml-1.5 bg-gray-100 hover:bg-gray-200 text-gray-800 font-normal py-1.5 px-3 rounded inline-flex items-center text-sm"
              onClick={() => handleSyncTaxonomyTypes(module)}
            >
              Sincronizar Taxonomías
            </button>
          ) : null}
        </div>
      ))}
      {moduleToInstall && (
        <ModalAlert
          onConfirm={handleInstallation}
          onCancel={() => setModuleToInstall(null)}
          confirmClass="bg-brand-primary text-white"
        >
          <div className="text-sm py-2 text-left">
            ¿Estás seguro de que deseas instalar <br />
            el módulo <b className="text-base">{moduleToInstall?.name}</b> <br />
            en la instancia <b className="text-base">{instanceName}</b>
            ?
            <hr className="my-2 border-t border-gray-200" />
            <span className="text-gray-500 text-sm">
              Se crearan entidades y guardará la configuración en settings
            </span>
          </div>
        </ModalAlert>
      )}
    </div>
  );
}

export function RouteEntityModules(props) {
  const { selectedInstance } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);

  return (
    <LayoutAdmin
      history={props.history}
      breadcrumbs={[{
        title: "Módulos"
      }]}
      TitleToolbarRight={() => (
        <InstanceInlineSelectorForMain selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} />
      )}
    >
      <div className="ion-padding">
        <Content {...props} selectedInstance={selectedInner} />
      </div>
    </LayoutAdmin>
  );
}
