import React, { useCallback, useRef, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonModal
} from '@ionic/react';
import { checkmarkCircleOutline, hourglassOutline } from 'ionicons/icons';

export const AlertLogger = ({ title, logs, isOpen, onDismiss }) => {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} className="logger-modal" keyboardClose={false} backdropDismiss={false}>
      <IonHeader>
        <IonToolbar color="medium">
          {logs?.length && logs[0] ? (
            <IonItem lines="none" color="medium">
              <div className="w-6 text-center" slot="start">
                <svg className={`animate-spin h-5 w-5`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
              <IonLabel>{logs[0].message}</IonLabel>
            </IonItem>
          ) : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {logs.map((log, index) => (
            index > 0 ? (
              <IonItem key={index}>
                <IonIcon icon={checkmarkCircleOutline} slot="start" color="medium" />
                <IonLabel color="medium">{log.message}</IonLabel>
              </IonItem>
            ) : null
          ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export const useAlertLogger = () => {
  const [logs, setLogs] = useState([]);
  const logsList = useRef([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const pushLog = useCallback((newLog) => {
    logsList.current = [{ message: newLog }, ...logsList.current]
    setLogs(logsList.current);
  }, [logs]);

  const present = () => setIsModalOpen(true);
  const dismiss = () => setIsModalOpen(false);

  return {
    pushLog,
    logs,
    present,
    dismiss,
    isModalOpen,
  };
};