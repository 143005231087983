import React, { useState } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonButtons,
  IonProgressBar
} from '@ionic/react';
import toast from 'react-hot-toast';
import { handleImportLogic } from './importLogic';
import { getConfig } from '../../config';
import { RawInputSelectDocumentExtended } from '../../components/Form';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import { withPrefix } from '../instance/utilsInstance';
import { ObjectFormWrapped } from '../../components/Form/RawInputObjectForm';

const ModalImportJson = ({ instance, isOpen, onDismiss, setImportProgress: setImportProgressProp, importProgress }) => {
  const [clientesFile, setClientesFile] = useState(null);
  const [contratosFile, setContratosFile] = useState(null);
  const [pagosFile, setPagosFile] = useState(null);
  const [contractModelIdInput, setContractModelIdInput] = useState('');
  const [isImporting, setIsImporting] = useState(false);

  const handleClientesFileChange = (event) => {
    setClientesFile(event.target.files[0]);
  };

  const handleContratosFileChange = (event) => {
    setContratosFile(event.target.files[0]);
  };

  const handlePagosFileChange = (event) => {
    setPagosFile(event.target.files[0]);
  };

  const handleContractModelIdInputChange = (docId) => {
    setContractModelIdInput(docId);
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} backdropDismiss={false}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Importar Collections JSON</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>Cerrar</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <p>
          Selecciona los archivos JSON para importar Clientes, Contratos y Pagos. Los datos serán adaptados a la nueva estructura.
        </p>
        <IonList>
          <IonItem>
            <IonLabel>Clientes</IonLabel>
            <input
              type="file"
              accept=".json"
              onChange={handleClientesFileChange}
              className="ml-2"
            />
          </IonItem>
          <IonItem>
            <IonLabel>Contratos</IonLabel>
            <input
              type="file"
              accept=".json"
              onChange={handleContratosFileChange}
              className="ml-2"
            />
          </IonItem>
          <IonItem>
            <IonLabel>Pagos</IonLabel>
            <input
              type="file"
              accept=".json"
              onChange={handlePagosFileChange}
              className="ml-2"
            />
          </IonItem>
          <IonItem>
            <IonLabel>Modelo de contratos</IonLabel>
            <ObjectFormWrapped>
              <RawInputSelectDocumentExtended
                instance={instance}
                entitySlug={withPrefix(instance, getConfig().modules.sales.contractsModelsEntitySlug)}
                title="Modelo de contrato"
                btnLabel="Buscar"
                onChange={handleContractModelIdInputChange}
                className={inputClasses.fieldInput || ''}
                showTriggerBtn={true}
              />
            </ObjectFormWrapped>
          </IonItem>
        </IonList>
        <IonProgressBar value={importProgress} color="primary" buffer={1}></IonProgressBar>
        <IonButton
          expand="full"
          onClick={() => {
            setIsImporting(true);
            handleImportLogic(instance, clientesFile, contratosFile, pagosFile, contractModelIdInput, setImportProgressProp)
              .catch(error => toast.error(error.toString())) // Handle errors and display toast
              .finally(() => setIsImporting(false));
          }}
          disabled={!clientesFile || !contratosFile || !pagosFile || !contractModelIdInput || isImporting}
        >
          Importar
        </IonButton>
      </IonContent>
    </IonModal>
  );
};

export default ModalImportJson;