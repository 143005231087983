import _ from 'lodash';
import { useSettings } from '../panel/useSettings';
import Model from '../../libs/ModelClass';
import config from '../../config';
import { useAlertLogger } from '../panel/AlertLogger';
import { useState } from 'react';

import migrationMain002 from './migrationsMain/002-eliminar-menuMain';
import migrationMain001 from './migrationsMain/001-renombrar-logos';
import toast from 'react-hot-toast';
import migrationInstance001 from './migrationsInstances/001-fieldsIds';

const migrationsMain = [
  // { key: '005-add-users', fn: migration002},
  // { key: '004-add-users', fn: migration002},
  // { key: '003-test', fn: migration001 },
  { key: '002-eliminar-menuMain', fn: migrationMain002 },
  { key: '001-renombrar-logos', fn: migrationMain001 },
  { key: '000-init' }
];

// Define las migraciones en un array de objetos
const migrations = [
  { key: '001-fieldsIds', fn: migrationInstance001 },
  { key: '000-init' }
];

// Crea un mapa de migraciones ordenado
const migrationsMainMap = migrationsMain.reduce((acc, { key, fn }) => ({ ...acc, [key]: fn }), {});

// Exporta la lista de claves de migraciones main
export const migrationsMainList = Object.keys(migrationsMainMap);

// Invierte el orden de las migraciones main
const migrationsMainListReversed = [...migrationsMainList].reverse();

// Crea un mapa de migraciones ordenado
const migrationsMap = migrations.reduce((acc, { key, fn }) => ({ ...acc, [key]: fn }), {});

// Exporta la lista de claves de migraciones
export const migrationsList = Object.keys(migrationsMap);

// Invierte el orden de las migraciones
const migrationsListReversed = [...migrationsList].reverse();

// Hook para gestionar las migraciones
export const useMigrations = () => {
  const settings = useSettings();
  const logger = useAlertLogger();
  const [isMigrating, setIsMigrating] = useState(false);

  // Función para migrar todas las instancias
  const migrateAllInstances = async (fromMigration, toMigration) => {
    logger.present();
    logger.pushLog('Obteniendo instancias');
    const InstancesModel = Model.extend(config.modules.instances.instancesEntitySlug);
    const instancesDocs = await InstancesModel.filterByAttributes({ deleted: 'false' });

    for (const instanceDoc of instancesDocs) {
      const counter = `${instancesDocs.indexOf(instanceDoc) + 1}/${instancesDocs.length} `;
      logger.pushLog(`${counter} ${instanceDoc.data.hash}: Iniciando migración`);
      console.log('instanceDoc', counter, instanceDoc);

      await migrateInstance(instanceDoc, fromMigration, toMigration);
      logger.pushLog(`${counter} ${instanceDoc.data.hash}: Migración finalizada`);
    }

    logger.dismiss();
    toast.success('Migración finalizada');
  };

  // Función para migrar una instancia
  const migrateInstance = async (instanceDoc, fromMigration, toMigration) => {
    const instanceHash = instanceDoc.data.hash;
    const instanceSettings = await settings.fetchForScope(instanceHash, false);
    const fromIndex = migrationsListReversed.indexOf(fromMigration);
    const toIndex = migrationsListReversed.indexOf(toMigration);

    // Validar que ambos migrations existan en la lista
    if (fromIndex === -1 || toIndex === -1) {
      console.log('Error en migración: fromMigration o toMigration no encontrado.');
      return;
    }

    // Obtener las migraciones a ejecutar (incluyendo desde 'from' hasta 'to')
    const migrationsToRun = migrationsListReversed.slice(fromIndex, toIndex + 1);
    // console.log('migrationsToRun', migrationsToRun, fromIndex, toIndex);
    for (const migration of migrationsToRun) {
      const migrationModule = migrationsMap[migration];
      // console.log('for', migration, migrationModule);
      if (_.isFunction(migrationModule)) {
        try {
          await migrationModule({ instanceDoc, instanceSettings });
          // console.log('do migration', migration);
        } catch (error) {
          logger.pushLog(`Error ejecutando migración ${migration}: ${error.message}`);
          toast.error(`Error en migración ${migration}`);
          logger.dismiss();
          console.error(error);
          return;
        }
      }
    }
  };

  // Función para migraciones de main
  const migrateMain = async (fromMigration, toMigration) => {
    logger.present();
    logger.pushLog('Iniciando migración de main');

    // Obtener los índices de las migraciones desde migrationsMainList
    const fromIndex = migrationsMainListReversed.indexOf(fromMigration);
    const toIndex = migrationsMainListReversed.indexOf(toMigration);

    // Validar que ambos migrations existan en la lista
    if (fromIndex === -1 || toIndex === -1) {
      logger.pushLog('Error: una de las migraciones no fue encontrada.');
      logger.dismiss();
      toast.error('Error en migración: fromMigration o toMigration no encontrado.');
      return;
    }

    // Obtener las migraciones a ejecutar (incluyendo desde 'from' hasta 'to')
    const migrationsToRun = migrationsMainListReversed.slice(fromIndex, toIndex + 1);
    // console.log('migrationsToRun', migrationsToRun, fromIndex, toIndex);

    // Ejecutar las migraciones
    for (const migration of migrationsToRun) {
      const migrationModule = migrationsMainMap[migration];
      if (_.isFunction(migrationModule)) {
        try {
          await migrationModule();
          // console.log('do migration', migration);
        } catch (error) {
          logger.pushLog(`Error ejecutando migración ${migration}: ${error.message}`);
          toast.error(`Error en migración ${migration}`);
          logger.dismiss();
          console.error(error);
          return;
        }
      }
    }

    logger.dismiss();
    toast.success('Migración de main finalizada');
  };

  // Función para reiniciar las migraciones
  const clearMigrationSettings = async () => {
    await settings.set('main', 'lastMigrationInstance', null);
  };

  // Función para reiniciar las migraciones de main
  const clearMigrationMainSettings = async () => {
    await settings.set('main', 'lastMigrationMain', null);
  };


  return {
    isMigrating,
    setIsMigrating,
    migrateMain,
    migrateAllInstances,
    migrateInstance,
    migrationsList,
    logger,
    clearMigrationSettings,
    clearMigrationMainSettings
  };
};

