import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useModule } from '../../libs/ModuleContext';
import Model from '../../libs/ModelClass';
import { sortDocsByField } from '../../libs/utils';

export const FilterMenuModel = Model.extend('filterMenu');
export const TaxonomyTypeModel = Model.extend('taxonomyTypes');

export default class Entity extends Model {
  static collectionName = 'entities';

  constructor(data) {
    super(data);
  }

  static getMainAttr(taxonomyTypesDocList) {
    return taxonomyTypesDocList
      ?.find(taxonomyType => taxonomyType?.data?.outstanding)
      ?.data?.nameSlug
      || 'name';
  }

  static getMainImgAttr(taxonomyTypesDocList) {
    return taxonomyTypesDocList
      ?.find(taxonomyType => taxonomyType?.data?.outstanding && taxonomyType?.data?.type === 'gallery')
      ?.data?.nameSlug;
  }
}

const nullData = {
  isLoading: true,
  doc: null,
  mainAttr: null,
  mainImgAttr: null,
  fieldsRequired: [],
  entityDoc: null,
  filterMenuDocList: null,
  taxonomyTypesDocList: null,
  filterMenuTaxonomyTypes: null,
  EntityModel: Entity,
  FilterMenuModel,
  TaxonomyTypeModel
};


export const saveEntityFromBlueprint = async ({ entity, filterMenuList, taxonomyTypesList }) => {
  try {
    let newBlueprint = {};
    const entityDoc = await Entity.createOrUpdate({
      ..._.omit(entity, 'id'),
      name: entity.name + ' (cloned)',
      nameSlug: entity.nameSlug + '_cloned'
    });
    const entityId = entityDoc.id;
    newBlueprint.entity = entityDoc.data;

    // taxonomy types
    const taxonomyTypesNewIdsMap = {};
    newBlueprint.taxonomyTypesList = [];
    await Promise.all(taxonomyTypesList.map(async (taxonomyType) => {
      const newTaxonomyType = await TaxonomyTypeModel.createOrUpdate({
        ..._.omit(taxonomyType, 'id'),
        entityId
      });
      taxonomyTypesNewIdsMap[taxonomyType.id] = newTaxonomyType.id;
      newBlueprint.taxonomyTypesList.push(newTaxonomyType.data);
    }));

    // filter menu
    newBlueprint.filterMenuList = [];
    await Promise.all(filterMenuList.map(async (filterMenuData) => {
      const filters = filterMenuData.filters.map((oldTaxonomyType) => {
        return {
          id: taxonomyTypesNewIdsMap[oldTaxonomyType.id]
        }
      });
      const newFilterMenuDoc = await FilterMenuModel.createOrUpdate({
        ...filterMenuData,
        filters,
        entityId
      });
      newBlueprint.filterMenuList.push(newFilterMenuDoc.data);
    }));

    return newBlueprint;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEntityBlueprintBySlug = async ({ entitySlug, entityDoc, withFilterMenu = true, withTaxonomyType = true }) => {
  const entityDocToUse = entityDoc || (await Entity.whereOne('nameSlug', '==', entitySlug));
  if (entityDocToUse) {
    let filterMenuDocList = null;
    if (withFilterMenu) {
      filterMenuDocList = await FilterMenuModel.filterByAttributes({ entityId: entityDocToUse.id });
      filterMenuDocList = filterMenuDocList?.filter((doc) => doc.data?.deleted !== true);
    }

    let taxonomyTypesDocList = null;
    if (withTaxonomyType) {
      taxonomyTypesDocList = await TaxonomyTypeModel.filterByAttributes({ entityId: entityDocToUse.id });
      taxonomyTypesDocList = taxonomyTypesDocList.filter((doc) => doc.data?.deleted !== true);
      taxonomyTypesDocList = sortDocsByField(taxonomyTypesDocList, 'sort');
    }
    
    return {
      entity: entityDocToUse.data,
      filterMenuList: withFilterMenu ? filterMenuDocList?.map(doc => doc.data) : null,
      taxonomyTypesList: withTaxonomyType ? taxonomyTypesDocList?.map(doc => doc.data) : null
    };
  }
  return null;
};

export const getEntitiesBlueprint = async () => {
  const entitiesBlueprints = {};
  const allEntities = await Entity.getAll();
  for (const entityDoc of allEntities) {
    if (entityDoc.data?.deleted) {
      continue;
    }
    const entitySlug = entityDoc.data.nameSlug;
    const entityBlueprint = await getEntityBlueprintBySlug({ entityDoc });
    entitiesBlueprints[entitySlug] = entityBlueprint;
  }
  return entitiesBlueprints;
};

export const getEntityFullBySlug = async (props) => {
  let {
    docId,
    docWhere, // { field, value }
    entityId,
    entitySlug,
    entityDoc,
    filterMenuSlug,
    includeFilters = true,
    onFetch
  } = props;
  try {
    if (!entityDoc) {
      if (entitySlug) {
        let records = await Entity.filterByAttributes({ nameSlug: entitySlug });
        records = records?.filter((doc) => doc.data?.deleted !== true);
        entityDoc = records[0];
      } 
      else if (entityId) {
        let doc = await Entity.findById(entityId);
        entityDoc = doc;
      }
    }
    if (entityDoc) {
      let ExtendedModel = Model.extend(entityDoc?.data?.nameSlug);
      let taxonomyTypesDocList = await TaxonomyTypeModel.filterByAttributes({ entityId: entityDoc.id });
      taxonomyTypesDocList = taxonomyTypesDocList.filter((doc) => doc.data?.deleted !== true);
      taxonomyTypesDocList = sortDocsByField(taxonomyTypesDocList, 'sort');
      
      const addFieldsRequired = [];
      taxonomyTypesDocList?.forEach((doc) => {
        if (doc.data.required && !doc.data.deleted) {
          addFieldsRequired.push(doc.data.nameSlug);
        }
      });
      
      const mainAttr = Entity.getMainAttr(taxonomyTypesDocList);
      const mainImgAttr = Entity.getMainImgAttr(taxonomyTypesDocList);
      
      let doc = null;
      if (docId) {
        doc = await ExtendedModel.findById(docId);
      }
      else if (docWhere) {
        doc = await ExtendedModel.findOneBy(docWhere?.field, docWhere?.value);
      }

      let filterMenuDocList = null;
      if (includeFilters) {
        filterMenuDocList = await FilterMenuModel.filterByAttributes({ 
          entityId: entityDoc.id,
          deleted: 'false',
          nameSlug: filterMenuSlug
        });
      }

      let filterMenuTaxonomyTypes = null;
      if (filterMenuSlug && filterMenuDocList?.length) {
        filterMenuTaxonomyTypes = [];
        const filterMenuDoc = filterMenuDocList?.find((filter) => filter.data.nameSlug === filterMenuSlug);
        filterMenuDoc?.data?.filters?.forEach((filter) => {
          const taxonomyType = taxonomyTypesDocList?.find((taxonomyType) => taxonomyType.id === filter.id);
          filterMenuTaxonomyTypes.push({
            id: filter.id,
            filter,
            taxonomyType: taxonomyType?.data
          });
        });
      }

      onFetch && onFetch({ filterMenuDocList, taxonomyTypesDocList, doc });
      
      return {
        doc,
        ExtendedModel,
        mainAttr,
        mainImgAttr, 
        fieldsRequired: addFieldsRequired,

        entitySlug: entityDoc?.data?.nameSlug,
        docId,
        entityId,
        filterMenuSlug,

        entityDoc,
        filterMenuDocList,
        taxonomyTypesDocList,
        filterMenuTaxonomyTypes,
        
        EntityModel: Entity,
        FilterMenuModel,
        TaxonomyTypeModel
      };
    }

    return nullData;
  } catch (error) {
    console.log('Error fetching:', error);
    return nullData;
  }
};

export const useEntityFullBySlug = (props) => {
  let {
    docId,
    docSnapshotSync,
    docWhere,
    entityId,
    entitySlug,
    entityDoc,
    filterMenuSlug = null,
    refreshers = [],
    onFetch,
    entitySpecs,
    specs
  } = props;
  const { fireEventReducer } = useModule();
  const [data, setData] = useState(entitySpecs || specs || nullData);

  useEffect(() => {
    const fetchData = async () => {
      if (entitySpecs) {
        setData(entitySpecs);
        return;
      }
      if (data?.doc?.id && data.doc.id === docId) {
        return;
      }
      
      setData({ isLoading: true });
      const spects = await getEntityFullBySlug({
        docId,
        docWhere,
        entityId,
        entitySlug,
        entityDoc,
        filterMenuSlug,
        includeFilters: !!filterMenuSlug,
        onFetch
      });

      // after read
      // TODO FIX use case of /:publicProfile
      const { doc, taxonomyTypesDocList } = spects;
      if (doc?.data && taxonomyTypesDocList?.length) {
        const modifiedDocData = await fireEventReducer('afterRead', { doc, entitySlug, taxonomyTypesDocList }, doc.data);  
        doc.data = modifiedDocData;
      }
      setData(spects);
    };
    
    if (entitySlug) {
      fetchData();
    }
  }, [docId, entitySlug, entitySpecs, filterMenuSlug, ...refreshers]);
  
  useEffect(() => {
    if (docSnapshotSync && data?.doc?.id) {
      return data.doc.onSnapshot(async (updatedDoc) => {
        const modifiedDocData = await fireEventReducer('afterRead', { 
          doc: updatedDoc,
          entitySlug: data.entitySlug,
          taxonomyTypesDocList: data.taxonomyTypesDocList
        }, updatedDoc.data);  
        updatedDoc.data = modifiedDocData;
        setData({ ...data, doc: updatedDoc });
      });
    }
  }, [docSnapshotSync, data?.doc?.id]);

  return data;
};

export const useGetLiveDocById = ({
  docId,
  entitySlug
}) => {
  const [ doc, setDoc ] = useState();

  const fetchDoc = async (docId) => {
    if (!docId) { return null; }
    let ExtendedModel = Model.extend(entitySlug);
    const doc = await ExtendedModel.findById(docId);
    setDoc(doc);
    return doc;
  };

  useEffect(() => {
    fetchDoc(docId);
  }, [docId]);
  
  useEffect(() => {
    if (doc?.id) {
      return doc.onSnapshot(async (updatedDoc) => {
        setDoc(updatedDoc);
      });
    }
  }, [doc]);

  return {
    doc
  };
};