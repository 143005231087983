import { processRenderProps } from './dataTypeUtils';
import {
  FormField,
  FormFieldSelect,
} from '../Form';
import _ from 'lodash';
import {
  FilterRenderTextSearch
} from '../Filters';
import { useMemo } from 'react';


const valueFormatter = (value) => {
  if (typeof value === 'undefined' || value === null) {
    return;
  }
  return value.toString();
};

export default {
  Render(props) {
    let { taxonomyTypeData, title, _fieldName, param, style, design } = processRenderProps(props);
    param.formatter = valueFormatter;
    
    return (
      <FormField
        {...props}
        {...style}
        {...param}
        name={_fieldName}
        type="text"
      />
    );
  },
  
  RenderFilter(props) {
    return <FilterRenderTextSearch {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam: (props) => (null),

  valueFormatter,

  RenderInputParams: ({ values, param, taxonomyTypeDoc, taxonomyTypesOfEntity }) => {
    const allFieldsOptions = useMemo(() => {
      let fields = [];
      
      taxonomyTypesOfEntity?.map((taxonomyTypeDoc) => {
        fields.push({
          value: taxonomyTypeDoc.data.nameSlug,
          label: `${taxonomyTypeDoc.data.name} [ ${taxonomyTypeDoc.data.nameSlug} ]`,
        });
      });

      return fields;
    }, [taxonomyTypesOfEntity]);

    return (
      <>
        <FormFieldSelect
          name="param.allTextsIndex"
          title="Campos a incluir en el índice de búsqueda de texto"
          helpText="Selecciona los campos cuyos textos se incluirán en el índice de búsqueda."
          options={allFieldsOptions}
          multiple={true}
        />
      </>
    );
  },
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderShowParams: ({ values }) => (null),
  
  RenderInputPreview: ({ values, formFieldProps }) => (null),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}