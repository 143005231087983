import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useEntityFullBySlug } from "../../modules/entity/Entity";
import { useListDoc, viewRowClasses } from "../../modules/entity/docCardsVariants";
import Model from "../../libs/ModelClass";
import { useModule } from "../../libs/ModuleContext";
import { IonButton, IonIcon, IonModal, IonHeader, IonToolbar, IonContent, IonItem, IonList, IonSearchbar, IonTitle, IonCheckbox } from "@ionic/react";
import { 
  menuOutline
} from 'ionicons/icons';
import { getJson } from "../../libs/utils";
import { RouteEntityCrudForm } from "../../modules/entity/RouteEntityCrudForm";
import BadgeLoading, { LoadingIcon } from "../ui/BadgeLoading";
import ObjectShow from "../../modules/entity/ObjectShow";
import { EntityCrudList } from "../../modules/entity/RouteEntityCrud";
import { usePanel } from "../../modules/panel/usePanel";
import SectionCrudObject from "../SectionCrudObject";
import { useForm } from "react-final-form";


const parseValue = (value, multiple) => {
  // ids array
  if (multiple) {
    return _.isArray(value) ? value : _.isString(value) ? [value] : [];
  }
  // single id
  else {
    if (_.isArray(value)) {
      return value[0];
    }
    return _.isString(value) ? value : null;
  }
};

export async function fetchDocsByIdsAndMode({ value, multiple, entitySlug }) {
  const ExtendedModel = Model.extend(entitySlug);
  let docs = [];
  // get only selecteds
  if (!_.isEmpty(value)) {
    let valueToUse = parseValue(value, multiple);

    if (multiple) {
      if (_.size(valueToUse)) {
        let results = await ExtendedModel.where('id', 'in', valueToUse);
        if (results) {
          docs = results;
        }
      }
    }
    else {
      if (valueToUse) {
        let result = await ExtendedModel.findById(valueToUse);
        if (result) {
          docs = [ result ];
        }
      }
    }
  }
  return docs;
}


export const ViewSelectedDocuments = (props) => {
  let {
    value,
    instance,
    entitySlug,
    multiple,  // Renamed from 'multipleple' for clarity
    fromJson,
    listIonColor
  } = props;

  const [ selectedDocs, setSelectedDocs ] = useState([]);
  const { isAllowed } = useModule();
  const entitySpecs = useEntityFullBySlug({ entitySlug }); // Fetch entity details
  const ListDoc = useListDoc({ entitySpecs, isAllowed });
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!value) {
        setSelectedDocs([]);
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const ExtendedModel = Model.extend(entitySlug);
      let docs;
      if (fromJson) {
        docs = await getJson(fromJson);
        docs = docs.map((json) => new ExtendedModel(json));
      } else {
        docs = await fetchDocsByIdsAndMode({ value, multiple, entitySlug });
      }
      setSelectedDocs(docs);
      setIsLoading(false);
    };

    fetchData();
  }, [value, entitySlug, multiple, fromJson]);

  if (!isAllowed(entitySlug, ["list", "instance:list"])) {
    return null;
  }

  const SelectedItemsList = ({ doc }) => (
    <ListDoc
      doc={doc}
      classes={{
        fieldLabel: 'medium',
        fieldValue: 'text-inherit'
      }}
    />
  );

  if (isLoading) {
    return (
      <div className="py-4 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (
    selectedDocs?.length ? (
      <div className="mt-1.5 rounded border border-gray-200">
        <SectionCrudObject
          instance={instance}
          entitySlug={entitySlug}
          docs={selectedDocs}
          reorder={false}
          showBtnMove={false}
          showBtnAdd={false}
          showBtnUpdate={false}
          showBtnDelete={false}
          showToast={false}
          ListItem={SelectedItemsList}
          listIonColor={listIonColor}
          classes={{
            sectionCrudContainer: "",
            formSectionContainer: "!mb-0",
            formSectionContainerOpen: "border-2 border-brand-secondary",
            formTitle: "!text-xs !font-semibold !text-inherit",
            cardContainer: "!m-0",
            listItemContainer: "",
          }}
        />
      </div>
    ) : (
      <p>No documents selected.</p> // Or handle the empty state as needed
    )
  );
};

const RawInputSelectDocumentExtended = (props) => {
  let {
    multiple,
    history,
    value,
    onChange = () => {},
    onAfterChange,
    instance,
    entitySlug,
    // filterMenuSlug,
    title,
    fields,
    btnLabel = 'Seleccionar',
    queryGetter,
    fromJson,
    inheritFilter,
    values = {},
    // form,
    taxonomyType,
    disabled,
    showTriggerBtn = true,
    apiGetter
  } = props;

  const form = useForm();
  let formValues = form.getState().values;
  const [ selectedDocs, setSelectedDocs ] = useState();
  const [ selectedDocsIds, setSelectedDocsIds ] = useState(value); // _.isArray(value) ? value : _.isString(value) ? [value] : []
  const [ isOpenListPopover, setIsOpenListPopover ] = useState(false);
  const [ docToUpdate, setDocToUpdate ] = useState();
  const { isAllowed } = useModule();
  const { selectedInstance } = usePanel();
  const [ filterSelected, setFilterSelected ] = useState({});
  const [ isLoading, setIsLoading ] = useState(false);
  const [ firstLoaded, setFirstLoaded ] = useState(false);

  const mode = fields ? 'object' : 'entity';
  const entitySpecs = useEntityFullBySlug({ 
    entitySlug: mode === 'entity' ? entitySlug : null,
    filterMenuSlug: props.filterMenuSlug || 'panelSearch'
  }); // void fetch on object mode
  const ListDoc = useListDoc({ entitySpecs, isAllowed });

  let defaultValueForm = {};
  let isDisabled = disabled || taxonomyType?.readOnly;
  let valuesToUse = formValues || values;
  let queryFilter = {};

  if (inheritFilter) {
    // set query filter map
    _.forEach(inheritFilter, (value, key) => {
      if (!_.isUndefined(valuesToUse[key]) && !_.isNull(valuesToUse[key])) {
        queryFilter[key] = valuesToUse[key];
      }
    });

    // apply inherit filters as default values for form
    _.forEach(inheritFilter, (value, key) => {
      defaultValueForm[key] = valuesToUse[key];
    });
  }

  queryGetter = queryGetter || (async (ExtendedModel) => {
    let docs;
    
    if (fromJson) {
      docs = await getJson(fromJson);
      docs = docs.map(json => new ExtendedModel(json));
    } else {
      docs = await fetchDocsByIdsAndMode({ value, multiple, entitySlug });
    }
    return docs;
  });

  const reset = () => {
    setSelectedDocs(null);
    setSelectedDocsIds(null);
  };

  apiGetter && apiGetter({
    isOpenModal: isOpenListPopover,
    closeModal: () => setIsOpenListPopover(false),
    openModal: () => setIsOpenListPopover(true),
    reset
  });

  useEffect(() => {
    if (!value) {
      reset();
    }
  }, [value]);

  const fetchData = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const ExtendedModel = Model.extend(entitySlug);
    let results = await queryGetter(ExtendedModel);
    let valueToUse = parseValue(value, multiple);
    if (valueToUse && results?.length) {
      if (multiple) {
        // sort results like value order
        results = _.map(valueToUse, (id) => results.find(doc => doc.id === id));
        setSelectedDocs(results);
        setSelectedDocsIds(results.map(doc => doc.id));
      } else {
        let selectedDoc = results.find(doc => doc.id === valueToUse);
        if (selectedDoc) {
          setSelectedDocs([selectedDoc]);
          setSelectedDocsIds([selectedDoc.id]);
        }
      }
    }
    setIsLoading(false);
    setFirstLoaded(true);
  };
  
  useEffect(() => {
    if (!value) {
      setSelectedDocs([]);
      setSelectedDocsIds([]);
    }
    if (value) {
      fetchData(); // TODO avoid extra fetching
    }
  }, [value, entitySlug, JSON.stringify(queryFilter)]);

  if (!isAllowed(entitySlug, ['list', 'instance:list'])) {
    return null;
  }

  const onClosePopover = () => {
    setIsOpenListPopover(false);
  };

  // TODO verificar funcionamiento
  const onAfterSaveForm = (savedItem) => {
    const forceAssignToForm = (value) => {
      onChange(null);
      onChange(value);
    };

    if (multiple) {
      // add
      if (docToUpdate.id === 'new') {
        let newIds = [ savedItem.id, ...selectedDocsIds ];
        setSelectedDocs([ savedItem, ...selectedDocs ]);
        setSelectedDocsIds(newIds);
        onChange(newIds);
      }
      // update
      else {
        let newSelectionIds = _.compact(_.filter(selectedDocsIds, id => id !== savedItem.id));
        newSelectionIds.push(savedItem.id);
        let newSelectedDocs = _.compact(_.filter(selectedDocs, selectedDoc => selectedDoc.id !== savedItem.id));
        newSelectedDocs.push(savedItem);
        setSelectedDocsIds(newSelectionIds);
        setSelectedDocs(newSelectedDocs);
        forceAssignToForm(newSelectionIds);
      }
    }
    else {
      setSelectedDocs([ savedItem ]);
      setSelectedDocsIds([ savedItem.id ]);
      forceAssignToForm(savedItem.id);
    }
    
    setDocToUpdate(null);
    onClosePopover();
  };

  const handleReorder = (reorderedMap) => {
    if (multiple) {
      let newOrderIds = reorderedMap.map(doc => doc.id);
      setSelectedDocsIds(newOrderIds);
      setSelectedDocs(reorderedMap);
      onChange([...newOrderIds]);
    }
  };

  const onCloseForm = () => {
    setDocToUpdate(null);
  };

  const setOpenModal = () => {
    if (mode === 'entity') {
      setIsOpenListPopover(true);
    }
    else if (mode === 'object') {
      setIsOpenListPopover(false);
    }
  };

  const onSelection = (selectedDoc, docs) => {  
    if (multiple) {
      const currentIds = new Set(selectedDocsIds);
      if (currentIds.has(selectedDoc?.id)) {
        currentIds.delete(selectedDoc?.id);
      } else {
        currentIds.add(selectedDoc?.id);
      }
      
      const updatedDocsIds = Array.from(currentIds);
      const updatedDocs = docs.filter(doc => updatedDocsIds.includes(doc.id));
  
      setSelectedDocsIds(updatedDocsIds);
      setSelectedDocs(updatedDocs);
      onChange(updatedDocsIds);
      onAfterChange && onAfterChange({ docs: updatedDocs });
    } else {
      const isSelected = selectedDoc?.id === selectedDocsIds?.[0];
  
      setSelectedDocsIds(isSelected ? [] : [selectedDoc?.id]);
      setSelectedDocs(isSelected ? [] : [selectedDoc]);
      onChange(isSelected ? null : selectedDoc?.id);
      onAfterChange && onAfterChange({ doc: isSelected ? null : selectedDoc });
      onClosePopover();
    }
  };  

  // control del filtro del listado
  const processFilter = ({ queryParams = {}, totalPages = 0 }) => {
    setFilterSelected({ ...queryParams });
  };

  const handleItemAction = (viewType, doc) => {
    if (viewType === 'form') {
      setDocToUpdate(doc || { id: 'new' });
      setOpenModal();
    }
  };

  const Card = ({ color, className, selectedDoc, doc }) => (
    <div className={`flex flex-row py-2 items-stretch w-full ${className}`}>
      {mode === 'entity' ? (
        <ListDoc 
          doc={doc}
          classes={{
            fieldLabel: color === 'medium' ? 'text-brand-medium-contrast' : '',
            fieldValue: 'text-inherit'
          }}
        />
      ) : null}

      {mode === 'object' ? (
        <ObjectShow
          instance={instance}
          entitySlug={entitySlug}
          value={doc}
          fields={fields}
          design="flat"
          classes={viewRowClasses}
        />
      ) : null}
    </div>
  );

  const SelectedItemsList = (({ doc }) => (<>
    <div className="text-sm text-gray-800">
      {doc?.data?.name}
    </div>
  </>));

  const SelectedItemsExtraActions = ({ doc }) => {
    const removeFromSelection = () => {
      let newSelectionIds = _.compact(_.filter(selectedDocsIds, id => id !== doc.id));
      let newSelectedDocs = _.compact(_.filter(selectedDocs, selectedDoc => selectedDoc.id !== doc.id));
      setSelectedDocsIds(newSelectionIds);
      setSelectedDocs(newSelectedDocs);
      onChange(newSelectionIds);
    };
    return (
      <IonButton onClick={removeFromSelection} size="small" fill="clear" color="secondary" shape="round">
        <IonCheckbox
          slot="start"
          checked={selectedDocsIds.includes(doc.id)}
          color="secondary"
        />
      </IonButton>
    );
  };

  return (<>
    {showTriggerBtn ? (<>
      {isLoading ? (
        <IonButton disabled={true} size="small" fill="outline" color="medium">
          <LoadingIcon className={'h-[18px] w-[18px] py-0.5 mr-2'} />
          {btnLabel}
        </IonButton>
      ) : (
        <IonButton onClick={setOpenModal} size="small" fill="outline" color="medium">
          <IonIcon icon={menuOutline} className="mr-2" slot="start" size="small"></IonIcon>
          {btnLabel}
        </IonButton>
      )}
    </>) : null}

    {/* selected docs */}
    {selectedDocs?.length ? (
      multiple ? (
        <div className="mt-1.5 rounded border border-gray-200">
          <SectionCrudObject
            instance={instance}
            entitySlug={entitySlug}
            editStyle="onsite"
            // title="Bloques seleccionados"
            docs={selectedDocs}
            reorder={true}
            showBtnMove={true}
            // hidde btns
            showBtnAdd={false}
            showBtnUpdate={false}
            showBtnDelete={false}
            showToast={false}
            // callbacks
            onReorder={handleReorder}            
            // UI
            ListItem={SelectedItemsList}
            ItemExtraActions={SelectedItemsExtraActions}
            // classes
            classes={{
              sectionCrudContainer: "",
              formSectionContainer: "!mb-0",
              formSectionContainerOpen: "border-2 border-brand-secondary",
              formTitle: "!text-xs !font-semibold !text-inherit",
              cardContainer: "!m-0",
              listItemContainer: ""
            }}
          />
        </div>
      ) : (
        <button onClick={setOpenModal} className="w-full">
          <div className="px-2 rounded-md overflow-hidden border border-gray-200">
            <Card {...{ selectedDoc: selectedDocs[0], doc: selectedDocs[0] }} />
          </div>
        </button>
      )
    ) : null}

    {/* entity list and form */}
    {!isDisabled ? (
      <IonModal
        isOpen={isOpenListPopover}
        onDidDismiss={onClosePopover}
        className="medium"
        initialBreakpoint={0.95}
        breakpoints={[0, 0.95]}
        backdropDismiss={true}
        backdropBreakpoint={0.5}
      >

        <IonContent>
          {/* selected */}
          <IonHeader className="">
            <IonToolbar color={selectedDocs?.length ? 'medium' : ''} className="px-1.5 !pt-1.5 pb-1">
              <div slot="start">
                <IonTitle className="px-0">{title}</IonTitle>
                {/* selected doc */}
                {/* {!multiple && selectedDocs?.length ? (<>
                  <h3 className="px-1 pt-1 text-xs font-semibold uppercase mb-2">
                    <button type="button" onClick={() => { setSelectedDocs(null); onChange(null); }} className="relative -top-0.5 mr-2 inline-block w-4 h-4 rounded-full !bg-brand-secondary !text-brand-secondary-contrast font-normal text-center">
                      <div className="relative -mt-0.5">x</div>
                    </button>
                    Seleccionado
                  </h3>
                  <div className="mb-2 px-2 w-full rounded-md overflow-hidden border border-gray-200">
                    <Card color="medium" {...{ selectedDoc: selectedDocs[0], doc: selectedDocs[0] }} />
                  </div>
                </>) : null} */}
              </div>
              <div slot="end" className="flex place-content-end">
                
              </div>
            </IonToolbar>
          </IonHeader>

          {/* list */}
          <IonList className="mt-0 pt-0 pb-96">
            {/* entity form */}
            {docToUpdate && entitySlug ? (
              <RouteEntityCrudForm
                context={{
                  entitySlug,
                  docId: docToUpdate.id,
                  asModal: true,
                  onAfterSave: onAfterSaveForm,
                  onClose: onCloseForm,
                  defaultValue: defaultValueForm
                }}
                classes={{
                  loadingContainer: "!py-4"
                }}
              />
            ) : null}
            {(entitySpecs?.isLoading) ? (
              <div className="pt-32 flex place-content-center content-center items-center font-brand-main">
                <BadgeLoading className="text-brand-dark" />
              </div>
            ) : null}

            {entitySpecs?.entityDoc ? (
              <EntityCrudList
                instance={instance}
                history={history}
                entitySpecs={entitySpecs}
                editStyle="modal"
                filterMenuSlug={'panelSearch'}
                selectedInstance={selectedInstance}
                parsedParams={filterSelected}
                processFilter={processFilter}
                handleActions={handleItemAction}
                showSelectionUI={true}
                isSelectionActivate={true}
                selectedDocsIds={selectedDocsIds}
                onSelection={onSelection}
                showBtnShow={false}
                title={false}
              />
            ) : null}
          </IonList>

        </IonContent>
      </IonModal>
    ) : null}
  </>);
};

export default RawInputSelectDocumentExtended;
