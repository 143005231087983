import _ from 'lodash';


const FilterRenderTextSearch = (props) => {
  const {
    values,
    filter,
    taxonomyType,
    overrideParams = {},
    DataTypeRender
  } = props;
  return (
    <DataTypeRender {...props} />
  );
};


const generateTextSearchData = (taxonomyType, formValues, filterValue) => {
  let trigramList = [];

  if (filterValue) {
    // Generate trigrams from filterValue (single string)
    const textClean = filterValue.toLowerCase().replace(/[^a-z0-9\s]/g, '').replace(/\s+/g, '');
    for (let i = 0; i <= textClean.length - 3; i++) {
      trigramList.push(textClean.substring(i, i + 3));
        }
      } else if (
    taxonomyType.data?.type === 'textSearch'
    && taxonomyType.data.param?.allTextsIndex?.length
  ) {
    // Generate trigrams from formValues (multiple fields)
    taxonomyType.data.param.allTextsIndex.forEach(fieldName => {
      const textValue = formValues[fieldName];
      if (typeof textValue === 'string') {
        const textClean = textValue.toLowerCase().replace(/[^a-z0-9\s]/g, '').replace(/\s+/g, '');
        for (let i = 0; i <= textClean.length - 3; i++) {
          trigramList.push(textClean.substring(i, i + 3));
        }
      }
    });
  }

  return {
    trigramList: trigramList
  };
};

export {
  FilterRenderTextSearch,
  generateTextSearchData
};
