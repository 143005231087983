import toast from "react-hot-toast";
import Model, { generateTimestampId } from "../../libs/ModelClass";
import config from "../../config";
import { withPrefix } from "../instance/utilsInstance";
import { replaceUndefinedAndNaNWithNull } from "../../libs/utils";
import { toAmount } from "../../components/Form/utilsCurrency";

export const mapClientes = (oldClientsList) => {
  return oldClientsList.map(oldClient => {
    return replaceUndefinedAndNaNWithNull({
      id: oldClient._id,
      firstName: oldClient.nombre,
      citizenNumber: oldClient.ci,
      address: oldClient.direccion,
      phoneNumber: oldClient.telefonos || "",
      createdAt: oldClient.created ? oldClient.created.$date : null,
      modifiedAt: oldClient.modified ? oldClient.modified.$date : null,
    });
  });
};

export const mapContratos = (oldContractsList, contractModelId) => {
  return oldContractsList.map(oldContract => {
    let status = "active";
    if (oldContract.finished) {
      status = "done";
    } else if (oldContract.canceled) {
      status = "nulled";
    } else if (oldContract.inArrears > 0) {
      status = "arrears";
    }

    return replaceUndefinedAndNaNWithNull({
      id: oldContract._id,
      name: oldContract.altId,
      customerId: oldContract.client,
      modelId: contractModelId,

      creditAmount: toAmount(oldContract.amount),
      firstDueDate: oldContract.expirationDay, 
      paidQty: oldContract.paymentsCant - oldContract.remainingPayments,
      paymentsQty: oldContract.paymentsCant,
      paidAmount: toAmount(oldContract.entrega),

      totalAmount: toAmount(oldContract.amortizationTotal),
      totalInterest: toAmount(oldContract.interestsTotal),
      
      disbursementInvoice: oldContract.entregaFactura,
      
      meta_f1: oldContract?.prodId?.f1,
      meta_f2: oldContract?.prodId?.f2,
      meta_f3: oldContract?.prodId?.f3,
      
      isOld: oldContract.isOld,
      status: status,
      issuedDate: oldContract.registro ? oldContract.registro.$date : null,
      createdAt: oldContract.created ? oldContract.created.$date : null,
      modifiedAt: oldContract.modified ? oldContract.modified.$date : null,
    });
  });
};

export const mapPagos = (oldPaymentsList) => {
  const newPaymentsList = [];

  for (const oldPayment of oldPaymentsList) {
    // Si es "Arrear", se omite este elemento
    if (oldPayment._type === "Arrear") continue;

    let status = "pending";
    if (oldPayment.paid) {
      status = "paid";
    } else if (oldPayment.expired) {
      status = "overdue";
    }

    newPaymentsList.push(
      replaceUndefinedAndNaNWithNull({
        id: oldPayment._id,
        num: oldPayment.nro,
        contractId: oldPayment.contract,
        customerId: oldPayment.client,
        amount: toAmount(oldPayment.amount || 0),
        remainingAmount: toAmount(oldPayment.remainder),
        amortization: toAmount(oldPayment.amortization),
        interests: toAmount(oldPayment.interests),
        status: status,
        expiration: oldPayment.expirationDate ? oldPayment.expirationDate.$date : null,
        paidDate: oldPayment.paidDate ? oldPayment.paidDate.$date : null,
        issuedDate: oldPayment.created ? oldPayment.created.$date : null,
        createdAt: oldPayment.created ? oldPayment.created.$date : null,
        modifiedAt: oldPayment.modified ? oldPayment.modified.$date : null,
      })
    );
  }

  return newPaymentsList;
};

export const processContactsData = async (clientesData, contratosData, pagosData, setImportProgress, instance) => {
  const totalClientes = clientesData.length;
  let processedClientes = 0;
  const contactsData = {};

  toast.loading('Iniciando procesamiento de datos...', { id: 'import' });

  //////////////////////////////////////////
  // Iterar sobre cada cliente
  //////////////////////////////////////////
  setImportProgress(0);
  
  // Procesar clientes y relacionar sus contratos y pagos
  const clientIdMap = {};
  const contractIdMap = {};

  for (let i = 0; i < Math.min(clientesData.length, 400); i++) { // Limitar a los primeros 100 clientes para pruebas
    const cliente = clientesData[i];
    // Generar nuevo ID para el cliente
    const newClientId = generateTimestampId(cliente.createdAt);
    clientIdMap[cliente.id] = newClientId; // Mapear el ID original al nuevo ID

    console.log('cliente.id', cliente.id)

    const newCliente = { // Crear un nuevo objeto cliente con el nuevo ID
      ...cliente,
      id: newClientId
    };

    const contratosCliente = contratosData.filter(
      (contrato) => {
        console.log('cliente.id', cliente.id, 'contrato.customerId', contrato.customerId);
        return contrato.customerId === cliente.id; // Usar el ID original del cliente para filtrar
      }
    );
    const pagosCliente = [];
    
    if (contratosCliente.length === 0) {
      console.log('No contratos encontrados para cliente.id', cliente.id);
    }

    for (const contrato of contratosCliente) {
      const pagosContrato = pagosData.filter(
        (pago) => pago.contractId === contrato.id // Usar el ID original del contrato para filtrar
      );
      if (pagosContrato.length === 0) {
        console.log('No pagos encontrados para contrato.id', contrato.id);
      }

      // Generar nuevo ID para el contrato
      const newContractId = generateTimestampId(contrato.createdAt);
      contractIdMap[contrato.id] = newContractId; // Mapear el ID original al nuevo ID

      pagosContrato.forEach(pago => {
        // Generar nuevo ID para el pago
        const newPaymentId = generateTimestampId(pago.createdAt);
        // Actualizar el id del pago y las referencias
        pago.id = newPaymentId;
        pago.contractId = newContractId;
        pago.clientId = newClientId;
      });
      pagosCliente.push(...pagosContrato);
    }

    contactsData[newClientId] = { // Usar el nuevo ID del cliente como clave
      client: newCliente,
      contracts: contratosCliente.map(contrato => ({
        ...contrato,
        id: contractIdMap[contrato.id],
        clientId: newClientId
      })),
      payments: pagosCliente.map(pago => ({
        ...pago,
        // Ya se actualizó el id, se usa directamente
        id: pago.id,
        contractId: contractIdMap[pago.contractId] || pago.contractId,
        clientId: newClientId
      })),
    };

    processedClientes++;
    const progress = (processedClientes / totalClientes);
    console.log('progress:', progress);
    setImportProgress(progress);
    await new Promise((resolve) => setTimeout(resolve));

    if (processedClientes % 100 === 0) {
      await new Promise((resolve) => setTimeout(resolve));
    }
  }

  console.log('contactsData', contactsData);
  
  //////////////////////////////////////////
  // Agrupar los datos de contactos por año
  //////////////////////////////////////////
  setImportProgress(0);

  toast.loading('Agrupando datos por año...', { id: 'import' });
  const contactsDataByYear = {};
  let processedContacts = 0;
  for (const contactId in contactsData) {
    const contact = contactsData[contactId];
    let earliestContractYear = null;

    if (contact.contracts && contact.contracts.length > 0) {
      const firstContract = contact.contracts[0];
      if (firstContract.createdAt) {
        earliestContractYear = new Date(firstContract.createdAt).getFullYear();
      } else {
        earliestContractYear = 'Sin fecha de creación en el primer contrato';
      }
    } else {
      earliestContractYear = 'Sin contratos';
    }

    if (!contactsDataByYear[earliestContractYear]) {
      contactsDataByYear[earliestContractYear] = {};
    }
    contactsDataByYear[earliestContractYear][contactId] = contact;

    processedContacts++;
    if (processedContacts % 100 === 0) {
      setImportProgress(processedContacts / Object.keys(contactsData).length);
      await new Promise((resolve) => setTimeout(resolve));
    }
  }
  console.log('contactsDataByYear', contactsDataByYear);

  //////////////////////////////////////////
  // Guardar los datos
  //////////////////////////////////////////
  setImportProgress(0);

  return null;

  toast.loading('Guardando datos, por favor espera...', { id: 'import' });

  const ContactsModel = Model.extend(withPrefix(instance, config.modules.contacts.contactsEntitySlug));
  const ContractsModel = Model.extend(withPrefix(instance, config.modules.sales.contractsEntitySlug));
  const PaymentsModel = Model.extend(withPrefix(instance, config.modules.sales.paymentsEntitySlug));

  // Definir el tamaño del batch (por ejemplo, 20 clientes por lote)
  const batchSize = 20;

  // Preparar arrays para acumular los datos a guardar
  let clientsBatch = [];
  let contractsBatch = [];
  let paymentsBatch = [];

  // Calcular el total de clientes para el progreso
  const allContacts = [];
  for (const year in contactsDataByYear) {
    for (const contactId in contactsDataByYear[year]) {
      allContacts.push(contactsDataByYear[year][contactId]);
    }
  }
  const totalContacts = allContacts.length;
  let savedContactsCount = 0;

  // Iterar sobre cada contacto y acumular sus datos en los arrays correspondientes
  for (const contactData of allContacts) {
    clientsBatch.push(contactData.client);
    contractsBatch.push(...contactData.contracts);
    paymentsBatch.push(...contactData.payments);
    savedContactsCount++;
    
    console.log(savedContactsCount, 'clients.lenght', clientsBatch.length, 'contracts.lenght', contractsBatch.length, 'payments.lenght', paymentsBatch.length);

    // Si alcanzamos el tamaño del batch, guardamos los datos de forma batch
    if (clientsBatch.length === batchSize) {
      await Promise.all([
        ContactsModel.createManyBatch(clientsBatch),
        ContractsModel.createManyBatch(contractsBatch),
        PaymentsModel.createManyBatch(paymentsBatch)
      ]);

      // Actualizar el progreso de la importación
      const progress = savedContactsCount / totalContacts;
      console.log("Progreso guardado:", progress);
      setImportProgress(progress);

      // Limpiar los arrays para el siguiente lote
      clientsBatch = [];
      contractsBatch = [];
      paymentsBatch = [];

      // Pequeña pausa para liberar el hilo si es necesario
      await new Promise((resolve) => setTimeout(resolve, 0));
    }
  }

  // Guardar cualquier remanente que no haya alcanzado el batchSize
  if (clientsBatch.length > 0) {
    await Promise.all([
      ContactsModel.createManyBatch(clientsBatch),
      ContractsModel.createManyBatch(contractsBatch),
      PaymentsModel.createManyBatch(paymentsBatch)
    ]);
    const progress = savedContactsCount / totalContacts;
    console.log("Progreso final guardado:", progress);
    setImportProgress(progress);
  }

  toast.success("Datos guardados correctamente.", { id: "guardando-datos" });

  // (Opcional) Logs de verificación
  console.log(
    `getAll ${withPrefix(instance, config.modules.contacts.contactsEntitySlug)}:`,
    await ContactsModel.getAll()
  );
  console.log(
    `getAll ${withPrefix(instance, config.modules.sales.contractsEntitySlug)}:`,
    await ContractsModel.getAll()
  );
  console.log(
    `getAll ${withPrefix(instance, config.modules.sales.paymentsEntitySlug)}:`,
    await PaymentsModel.getAll()
  );

  toast.success('Datos guardados correctamente.', { id: 'guardando-datos' });

  // console logs de hacer getAll a cada model
  console.log(`getAll ${withPrefix(instance, config.modules.contacts.contactsEntitySlug)}:`, await Model.extend(withPrefix(instance, config.modules.contacts.contactsEntitySlug)).getAll());
  console.log(`getAll ${withPrefix(instance, config.modules.sales.contractsEntitySlug)}:`, await Model.extend(withPrefix(instance, config.modules.sales.contractsEntitySlug)).getAll());
  console.log(`getAll ${withPrefix(instance, config.modules.sales.paymentsEntitySlug)}:`, await Model.extend(withPrefix(instance, config.modules.sales.paymentsEntitySlug)).getAll());

  return contactsDataByYear;
};

export const handleImportLogic = async (instance, clientesFile, contratosFile, pagosFile, contractModelIdInput, setImportProgress) => {
  if (!clientesFile || !contratosFile || !pagosFile) {
    toast.error('Por favor, selecciona un archivo JSON para cada tipo (Clientes, Contratos, Pagos).');
    return;
  }

  try {
    setImportProgress(0);

    const readerClientes = new FileReader();
    const readerContratos = new FileReader();
    const readerPagos = new FileReader();

    const clientesPromise = new Promise((resolve, reject) => {
      readerClientes.onload = async (event) => {
        try {
          const clientesJsonContent = JSON.parse(event.target.result);
          const mappedClientes = mapClientes(clientesJsonContent);
          resolve(mappedClientes);
        } catch (error) {
          console.error('Error al parsear el JSON de Clientes:', error);
          reject('Error al parsear el archivo JSON de Clientes.');
        }
      };
      readerClientes.onerror = () => {
        reject('Error al leer el archivo de Clientes.');
      };
      readerClientes.readAsText(clientesFile);
    });

    const contratosPromise = new Promise((resolve, reject) => {
      readerContratos.onload = async (event) => {
        try {
          const contratosJsonContent = JSON.parse(event.target.result);
          const contractModelId = contractModelIdInput;
          if (!contractModelId) {
            toast.error('Por favor, ingresa el Contract Model ID para importar contratos.');
            return reject('Por favor, ingresa el Contract Model ID para importar contratos.');
          }
          const mappedContratos = mapContratos(contratosJsonContent, contractModelId);
          resolve(mappedContratos);
        } catch (error) {
          console.error('Error al parsear el JSON de Contratos:', error);
          reject('Error al parsear el archivo JSON de Contratos.');
        }
      };
      readerContratos.onerror = () => {
        reject('Error al leer el archivo de Contratos.');
      };
      readerContratos.readAsText(contratosFile);
    });

    const pagosPromise = new Promise((resolve, reject) => {
      readerPagos.onload = async (event) => {
        try {
          const pagosJsonContent = JSON.parse(event.target.result);
          const mappedPagos = mapPagos(pagosJsonContent);
          resolve(mappedPagos);
        } catch (error) {
          console.error('Error al parsear el JSON de Pagos:', error);
          reject('Error al parsear el archivo JSON de Pagos.');
        }
      };
      readerPagos.onerror = () => {
        reject('Error al leer el archivo de Pagos.');
      };
      readerPagos.readAsText(pagosFile);
    });

    toast.loading('Leyendo datos, por favor espera...', { id: 'import' });

    const [clientesData, contratosData, pagosData] = await Promise.all([
      clientesPromise,
      contratosPromise,
      pagosPromise
    ]);

    console.log('raw clientesData', clientesData);
    console.log('raw contratosData', contratosData);
    console.log('raw pagosData', pagosData);
  
    await processContactsData(clientesData, contratosData, pagosData, setImportProgress, instance);
  } catch (error) {
    toast.error(error.message);
    console.error(error);
  }
};