import _ from 'lodash';
import InvoiceItemsTableLazy from './InvoiceItemsTableLazy';


const RenderForm = InvoiceItemsTableLazy;

const RenderShow = (props) => {
  const { displayedValue } = props;

  console.log('invoice items', displayedValue);

  return (
    <div className="">
    </div>
  );
};

const dataTypePaymentConfig = {
  RenderForm,
  RenderShow
};

export default dataTypePaymentConfig;
