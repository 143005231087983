import _ from 'lodash';
import Model from '../../libs/ModelClass';

import text from './text';
import textArea from './textArea';
import textSearch from './textSearch'; // Import TextSearch
import textId from './textId';
import select from './select';
import multiselect from './multiselect';
import boolean from './boolean';
import number from './number';
import numberId from './numberId';
import numberAmount from './numberAmount';
import date from './date';
import gps from './gps';
import gallery from './gallery';
import selectOneEntityDocument from './selectOneEntityDocument';
import selectManyEntityDocument from './selectManyEntityDocument';
import coded from './coded';
import { getGeofire } from '../../firebase';
import relatedListMaterials from './relatedListMaterials';
import selectCity from './selectCity';
import list from './list';
import phone from './phone';
import { verifyOnModelTextId } from '../Form/RawInputTextId';
import time from './time';
import collection from './collection';
import object from './object';
import color from './color';
import { getJson } from '../../libs/utils';
import { generateTextSearchData } from '../Filters/FilterTextSearch';


const typeOptions = [
  // basic
  { key: 'text', name: 'Texto' },
  { key: 'textArea', name: 'Texto extenso' },
  { key: 'textSearch', name: 'Búsqueda de Texto' }, // Add TextSearch to typeOptions
  { key: 'textId', name: 'Texto ID' },
  { key: 'boolean', name: 'Booleano' },
  { key: 'number', name: 'Número' },
  { key: 'date', name: 'Fecha' },
  { key: 'time', name: 'Hora' },
  { key: 'select', name: 'Selección' },
  { key: 'multiselect', name: 'Selección múltiple' },
  // complex
  { key: 'numberId', name: 'Número ID' },
  { key: 'numberAmount', name: 'Monto de dinero' },
  { key: 'gps', name: 'GPS' },
  { key: 'gallery', name: 'Galería de Imágenes' },
  { key: 'selectOneEntityDocument', name: 'Seleccionar documento' },
  { key: 'selectManyEntityDocument', name: 'Seleccionar varios documentos' },
  { key: 'coded', name: 'Codificado' },
  { key: 'relatedListMaterials', name: 'Lista relacionada de Materias primas' },
  { key: 'selectCity', name: 'Selección de Ciudad' },
  { key: 'list', name: 'Lista de items' },
  { key: 'phone', name: 'Número de teléfono móvil' },
  // { key: 'collection', name: 'Colección anidada' } // for code only
  // { key: 'object', name: 'Sub objeto anidado' } // for code only
  { key: 'color', name: 'Color' },
];

const typeConfigs = {
  text,
  textArea,
  textSearch, // Add TextSearch to typeConfigs
  textId,
  select,
  multiselect,
  boolean,
  number,
  numberId,
  numberAmount,
  date,
  gps,
  gallery,
  selectOneEntityDocument,
  selectManyEntityDocument,
  coded,
  relatedListMaterials,
  selectCity,
  list,
  phone,
  time,
  collection,
  object,
  color
};

// Listeners for all entities events
const events = {
  beforeSave,
  afterSave,
  afterRead
};

export {
  typeOptions,
  typeConfigs,
  events,
};

async function beforeSave ({ entitySlug, taxonomyTypesDocList }, formValues) {
  for (const taxonomyType of taxonomyTypesDocList) {

    // Text Search
    if (taxonomyType.data?.type === 'textSearch') {
      let nameSlug = taxonomyType.data.nameSlug;
      let { textSearch_trigram } = generateTextSearchData(taxonomyType, formValues);
      formValues[nameSlug + '_trigram'] = textSearch_trigram;
    }

    // Number ID
    if (taxonomyType.data?.type === 'numberId') {
      let nameSlug = taxonomyType.data.nameSlug;
      const ExtendedModel = Model.extend(entitySlug);
      let docCount = await ExtendedModel.count();
      if ( !formValues[nameSlug] || formValues[nameSlug] < 0 ) {
        formValues[nameSlug] = docCount + 1;
      }
    }
    // GPS coordinates
    if (taxonomyType.data?.type === 'gps') {
      const geofire = getGeofire();
      let nameSlug = taxonomyType.data.nameSlug;
      if ( formValues[nameSlug] ) {
        const { lat, lng } = formValues[nameSlug];
        let geohash = geofire.geohashForLocation([lat, lng]);
        if (taxonomyType.data.param?.accuracyType === 'zone' && taxonomyType.data.param?.accuracyLevel) {
          geohash = geohash.substring(0, taxonomyType.data.param.accuracyLevel);
        }
        formValues[nameSlug + 'Hash'] = geohash;
      }
    }
    // OMIT parse directly on input
      // multiselect: pass to true map
      // if (taxonomyType.data?.type === 'multiselect') {
      //   let nameSlug = taxonomyType.data.nameSlug;
      //   if (_.isArray(formValues[nameSlug])) {
      //     let toObject = {};
      //     formValues[nameSlug].forEach(item => {
      //       toObject[item] = true;
      //     });
      //     formValues[nameSlug] = toObject;
      //   }
      // }

    // selectOneEntityDocument: normalize data
    if (taxonomyType.data?.type === 'selectOneEntityDocument') {
      let toNormalize = [];
      // get taxonomy types to normalize
      taxonomyTypesDocList?.map((taxonomyTypeDoc) => {
        if (
          taxonomyTypeDoc.data?.type === 'selectOneEntityDocument'
          && taxonomyTypeDoc.data.param.normalizedFields?.length
          && (
            taxonomyTypeDoc.data.param.entityNameSlug
            || taxonomyTypeDoc.data.param.fromJson
          )
        ) {
          toNormalize.push(taxonomyTypeDoc);
        }
      });
      // normalize data
      if (toNormalize.length) {
        for (const taxonomyTypeDoc of toNormalize) {
          let foreignKey = formValues[taxonomyTypeDoc.data.nameSlug];
          let relatedData;
          if (taxonomyTypeDoc.data.param.entityNameSlug && !taxonomyTypeDoc.data.param.fromJson) {
            const doc = await Model.extend(taxonomyTypeDoc.data.param.entityNameSlug).findById(foreignKey);
            relatedData = doc?.data;
          }
          else if (taxonomyTypeDoc.data.param.fromJson) {
            const jsonData = await getJson(taxonomyTypeDoc.data.param.fromJson);
            relatedData = jsonData.find(item => item[taxonomyTypeDoc.data.param.fieldValue] === foreignKey);
          }
          if (relatedData) {
            const normalizedFieldPrefix = _.replace(taxonomyTypeDoc.data.nameSlug, 'Id', '_');
            // copy normalized fields
            taxonomyTypeDoc.data.param.normalizedFields.forEach(field => {
              formValues[normalizedFieldPrefix + field] = relatedData[field];
            });
            // copy label field
            if (taxonomyTypeDoc.data.param.fromJson) {
              formValues[normalizedFieldPrefix + 'mainAttr'] = relatedData[taxonomyTypeDoc.data.param.fieldLabel];
            }
            // TODO
            // if (taxonomyTypeDoc.data.param.entityNameSlug) {
            //   let mainAttr = Entity.getMainAttr(taxonomyTypesDocList);
            //   formValues[taxonomyTypeDoc.data.nameSlug + 'Label'] = relatedData[];
            // }
          }
        }
      }
    }
    // selectCity: compact nested objects
    if (taxonomyType.data?.type === 'selectCity') {
      let nameSlug = taxonomyType.data.nameSlug;
      if (_.isObject(formValues[nameSlug])) {
        let { country, countryIso2, state, city, labels } = formValues[nameSlug];
        formValues[nameSlug + 'Country'] = country;
        formValues[nameSlug + 'CountryIso2'] = countryIso2;
        formValues[nameSlug + 'State'] = state;
        formValues[nameSlug + 'City'] = city;
        formValues[nameSlug + 'Labels'] = [...labels];
        Reflect.deleteProperty(formValues, nameSlug);
      }
      else if (!formValues[nameSlug]) {
        formValues[nameSlug + 'Country'] = null;
        formValues[nameSlug + 'CountryIso2'] = null;
        formValues[nameSlug + 'State'] = null;
        formValues[nameSlug + 'City'] = null;
        formValues[nameSlug + 'Labels'] = null;
      }
    }
    // Text ID: unique slugs
    if (taxonomyType.data?.type === 'textId') {
      let nameSlug = taxonomyType.data.nameSlug;
      if (formValues[nameSlug]) {
        const verifyResult = await verifyOnModelTextId({ 
          textId: formValues[nameSlug],
          field: nameSlug,
          entitySlug,
          docId: formValues?.id
        });
        if (!verifyResult.isAvailable) {
          throw new Error('ID no disponible, debe elegir otro.');
        }
      }
    }
  }
  return formValues;
};

async function afterSave ({ entitySlug, taxonomyTypesDocList }, itemDoc) {
  // for (const taxonomyType of taxonomyTypesDocList) {
  //   if (taxonomyType.data?.type === 'relatedListMaterials') {
  //     console.log('relatedListMaterials', itemDoc)
  //     let nameSlug = taxonomyType.data.nameSlug;
  //     if ( itemDoc[nameSlug] && itemDoc.id ) {
  //       // const {  } = itemDoc[nameSlug];
  //     }
  //   }
  // }
  return itemDoc;
};

async function afterRead ({ doc, entitySlug, taxonomyTypesDocList }, formValues) {
  for (const taxonomyType of taxonomyTypesDocList) {
    // multiselect: parse from true map
    // OMIT parse directly on input
      // if (taxonomyType.data?.type === 'multiselect') {
      //   let nameSlug = taxonomyType.data.nameSlug;
      //   let toObject = [];
      //   _.forEach(formValues[nameSlug], (val, key) => {
      //     toObject.push(key);
      //   });
      //   formValues[nameSlug] = toObject;
      // }
    // selectCity: expand nested objects
    if (taxonomyType.data?.type === 'selectCity') {
      let nameSlug = taxonomyType.data.nameSlug;
      if (formValues[nameSlug + 'Country']) {
        formValues[nameSlug] = {
          country: formValues[nameSlug + 'Country'],
          countryIso2: formValues[nameSlug + 'CountryIso2'],
          state: formValues[nameSlug + 'State'],
          city: formValues[nameSlug + 'City'],
          labels: formValues[nameSlug + 'Labels']
        };
      }
    }
  }
  return formValues;
};

// Parts for each DataType {
//   Render: ({ fieldName, taxonomyType, overrideParams, fieldsRequired }) => (FormField),
//   RenderFilter: (props) => (null),
//   RenderFilterParam: (props) => (null),
//   valueFormatter: (value) => (formatedValue),
//   RenderInputParams: ({ values, param }) => (null),
//   RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
//   RenderShowParams: ({ values }) => (null),
//   RenderInputPreview: ({ values, formFieldProps }) => (null),
//   getDefaultTaxonomyTypeDesign: () => ('default')
// }
export const DataTypePart = (field, props) => {
  const typeConfig = typeConfigs[props?.taxonomyType?.type];
  
  if (!typeConfig || !typeConfig[field]) {
    return null;
  }

  return typeConfig[field](props);
};