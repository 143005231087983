import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import TaxonomyType from './TaxonomyType';
import SectionCrudModel from "../../components/SectionCrudModel";
import { typeOptions, typeConfigs } from "../../components/DataTypes";
import _ from "lodash";

import {
  FormField,
  FormFieldSelect,
  FormFieldCheckbox,
  FormFieldTextArea
} from '../../components/Form';
import IonBtnLoading from '../../components/ui/IonBtnLoading';

export const RenderFilterPreview = (props) => {
  let { form, values, entitySlug } = props;
  const propsToUse = {
    taxonomyType: {
      ...values,
      title: values.name,
      placeholder: values.name,
      name: values.name,
      nameSlug: 'preview.filter'
    },
    entitySlug,
    form,
  };

  const typeConfig = typeConfigs[values?.type];

  if (!typeConfig || !typeConfig.RenderFilter) {
    return null;
  }

  return typeConfig.RenderFilter(propsToUse);
};

export const RenderFilterParam = (props) => {
  const type = props?.taxonomyType?.type;
  const typeConfig = typeConfigs[type];
  if (!typeConfig || !typeConfig.RenderFilterParam) {
    return <b>No disponible</b>;
  }
  return typeConfig.RenderFilterParam(props);
};

export const RenderInputParams = ({ entityDoc, values, taxonomyTypeDoc, entitySlug, formApi, taxonomyTypesOfEntity }) => {
  const { type, param } = values;
  const typeConfig = typeConfigs[type];

  if (!typeConfig || !typeConfig.RenderInputParams) {
    return null;
  }

  return typeConfig.RenderInputParams({ entityDoc, values, param, taxonomyTypeDoc, entitySlug, formApi, taxonomyTypesOfEntity });
};

export const RenderInputPreview = ({ values, taxonomyTypeDoc, entitySlug, formApi }) => {
  const { name, nameSlug, type, param } = values;
  const formFieldProps = {
    type,
    title: name,
    placeholder: name,
    ...param,
    name: 'preview.example'
  };

  const typeConfig = typeConfigs[type];

  if (!typeConfig || !typeConfig.RenderInputPreview) {
    return null;
  }

  return typeConfig.RenderInputPreview({ values, formFieldProps, taxonomyTypeDoc, entitySlug, formApi });
};

export const RenderShowParams = ({ values }) => {
  const type = values.type;
  const typeConfig = typeConfigs[type];

  if (!typeConfig || !typeConfig.RenderShowParams) {
    return null;
  }

  return typeConfig.RenderShowParams({ values });
};

export const RenderShowPreview = ({ values }) => {
  const { name, nameSlug, type, param } = values;
  const typeConfig = typeConfigs[type];

  if (!typeConfig || !typeConfig.RenderShowPreview) {
    return null;
  }

  return typeConfig.RenderShowPreview({ values });
};

// default => default
export const getDefaultTaxonomyTypeDesign = (values) => {
  const typeConfig = typeConfigs[values.type];

  if (!typeConfig || !typeConfig.getDefaultFormField) {
    return null;
  }

  return typeConfig.getDefaultFormField(values);
};

const TaxonomyTypeForm = ({ entityDoc, entitySlug, doc, onClose, onSave, taxonomyTypesOfEntity }) => {
  const fieldsRequired = ['name', 'type'];
  const [isSaving, setIsSaving] = useState(false);
  const [taxonomyToWatch, setTaxonomyToWatch] = useState();

  useEffect(() => {
    fetchTaxonomyType();
  }, [])

  const fetchTaxonomyType = async () => {
    if (doc.data?.show?.taxonomyToWatch && !taxonomyToWatch) {
      const taxonomyToWatch = await TaxonomyType.findById(doc.data?.show?.taxonomyToWatch);
      setTaxonomyToWatch( taxonomyToWatch );
    }
  };

  const handleSubmit = async (values) => {
    if (!values.nameSlug) {
      values.nameSlug = _.camelCase(values.name || '');
    }
    setIsSaving(true);
    await onSave(values);
    setIsSaving(false);
    toast.success('Los datos se han guardado correctamente');
  };

  const options = _.map(typeOptions, ({ key, name }) => ({ value: key, label: name }));

  const validateForm = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = ' '; // Requerido
    }
    if (!values.type) {
      errors.type = ' '; // Requerido
    }
    return errors;
  };

  const getDefaultValue = () => {
    let values = { ...doc?.data }
    values.show = values.show || {};
    values.show.condition = values.show.condition || 'always';
    values.style = values.style || { design: 'default' };
    return values;
  }

  return (
    <div key={doc.id} className="bg-white rounded p-4 m-4">
      <Form
        onSubmit={handleSubmit}
        initialValues={getDefaultValue()}
        validate={validateForm}
        render={({ form, handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit} className="space-y-8">
              {/* Basic */}
              <FormField name="name" title="Nombre" placeholder="Nombre" fieldsRequired={fieldsRequired} showLabel={true} />
              <FormField name="nameSlug" title="ID" fieldsRequired={fieldsRequired} 
                onChange={(value) => {
                  form.change('nameSlug', _.camelCase(value || ''));
                }}
                showLabel={true}
              />
              <FormFieldSelect name="type" title="Tipo de dato" 
                options={options} fieldsRequired={fieldsRequired} 
                onSelect={(newType) => form.change('style.design', getDefaultTaxonomyTypeDesign({...values, type: newType}) || 'default' )}
                showLabel={true}
              />
              
              {values?.nameSlug ? (<>
                
                {/* Params */}
                <div className="rounded bg-gray-200 p-5 space-y-2">
                  <FormFieldCheckbox name="forMainModerator" title="Visible sólo para moderadores (main)" fieldsRequired={fieldsRequired} />
                  <FormFieldCheckbox name="forOwner" title="Visible sólo para el propietario del doc" fieldsRequired={fieldsRequired} />
                  <FormFieldCheckbox name="hidden" title="Ocultar de vistas y formularios" fieldsRequired={fieldsRequired} />
                  {/* <div className="my-2 border-b border-gray-400"></div> */}
                  
                  {/* Show conditions */}
                  {/* <div className="rounded bg-gray-200 p-5 mt-5">
                    <FormFieldSelect
                      name="show.condition"
                      title="Visibilidad"
                      options={[
                        { value: 'always', label: 'Ver siempre' },
                        { value: 'hide', label: 'Ocultar según atributo' },
                        { value: 'show', label: 'Mostrar según atributo' }
                      ]}
                    />
                    {(values.show?.condition === 'hide' || values.show?.condition === 'show') ? (<>
                      <FormFieldSelectDocumentModel
                        name="show.taxonomyToWatch"
                        modelName="taxonomyTypes"
                        onSelect={(selectedId, selectedItem) => setTaxonomyToWatch(selectedItem)}
                        queryGetter={(ExtendedModel) => TaxonomyType.findByEntityId(values.entityId)}
                        title="Atributo"
                        placeholder="Atributo"
                        />
                        <RenderShowConditionParams values={values} taxonomyToWatch={taxonomyToWatch} />
                        </>) : ''}
                      </div> */}
                </div>

                {/* Field form */}
                {values.type ? (<>
                  {/* Form */}
                  <div className="rounded bg-gray-200 p-2 mt-5 space-y-2">
                    <h3 className="mb-2 pb-2 border-b border-gray-300 font-semibold">Formulario</h3>
                    <FormFieldCheckbox name="required" title="Requerido en formularios" fieldsRequired={fieldsRequired} />
                    <FormFieldCheckbox name="readOnly" title="Sólo lectura en formularios" fieldsRequired={fieldsRequired} />
                    <FormFieldCheckbox name="hiddeInForms" title="Ocultar en formularios" fieldsRequired={fieldsRequired} />
                    <FormFieldTextArea
                      name="param.descriptionText"
                      title="Texto de descripción"
                      placeholder="Texto de descripción"
                      showLabel={true}
                    />
                    <FormFieldTextArea
                      name="param.helpText"
                      title="Texto de ayuda"
                      placeholder="Texto de ayuda"
                      showLabel={true}
                    />
                    <div className="my-2 space-y-2">
                      <RenderInputParams
                        values={values}
                        taxonomyTypeDoc={doc}
                        entitySlug={entitySlug}
                        entityDoc={entityDoc}
                        formApi={form}
                        taxonomyTypesOfEntity={taxonomyTypesOfEntity}
                      />
                    </div>
                    {/* Example */}
                    {typeConfigs[values.type]?.RenderInputPreview ? (
                      <div className="mt-4 p-2 bg-white rounded">
                        <div className="pb-2 border-b border-gray-200">
                          <h3 className="font-semibold">Vista en formulario</h3>
                        </div>
                        <div className="my-10">
                          <RenderInputPreview values={values} taxonomyTypeDoc={doc} entitySlug={entitySlug} formApi={form} />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  
                  {/* Show */}
                  <div className="rounded bg-gray-200 p-2 mt-5 space-y-2">
                    <h3 className="mb-2 pb-2 border-b border-gray-300 font-semibold">Vista</h3>
                    {/* Params */}
                    <FormFieldCheckbox name="outstanding" title="Destacado en listas" fieldsRequired={fieldsRequired} />
                    <FormFieldCheckbox name="hiddeInViews" title="Ocultar en vistas" fieldsRequired={fieldsRequired} />
                    {typeConfigs[values.type]?.RenderShowParams ? (
                      <RenderShowParams values={values} taxonomyTypeDoc={doc} entitySlug={entitySlug} />
                    ) : null}
                    {/* Example */}
                    {typeConfigs[values.type]?.RenderShowPreview ? (
                      <div className="mt-4 p-2 bg-white rounded">
                        <div className="pb-2 border-b border-gray-200">
                          <h3 className="font-semibold">Vista en listas y views</h3>
                        </div>
                        <RenderShowPreview values={values} taxonomyTypeDoc={doc} entitySlug={entitySlug} />
                      </div>
                    ) : null}
                  </div>

                  {/* Filter */}
                  <div className="rounded bg-gray-200 p-2 mt-5 space-y-2">
                    <h3 className="mb-2 pb-2 border-b border-gray-300 font-semibold">Filtro</h3>
                    {/* Params */}
                    <RenderFilterParam {...{form, values, taxonomyType: values}} />
                    {/* Example */}
                    {typeConfigs[values.type]?.RenderFilter ? (
                      <div className="mt-4 p-3 bg-white rounded">
                        <div className="pb-2 border-b border-gray-200">
                          <h3 className="font-semibold">Vista en filtros</h3>
                        </div>
                        <div className="my-10">
                          <RenderFilterPreview {...{
                            form,
                            values,
                            entitySlug
                          }} />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>) : null}

              </>) : null}

              {/* Actions */}
              <div className={`!mt-10 pt-4 border-t border-gray-300 grid grid-cols-2 gap-4`}>
                <IonBtnLoading
                  type="button"
                  label={'Cerrar'}
                  size="small"
                  fill="solid"
                  color="light"
                  onClick={onClose}
                />
                <IonBtnLoading
                  type="submit"
                  label={isSaving ? 'Guardando...' : 'Guardar'}
                  size="small"
                  fill="solid"
                  color="primary"
                  disabled={submitting || isSaving}
                />
              </div>
            </form>
          )
        }}
      />
    </div>
  );
};

export function Content({ entityId, entitySlug, entityDoc }) {
  const [ taxonomyTypesOfEntity, setTaxonomyTypesOfEntity ] = useState();

  const handleBeforeSave = (docData) => {
    docData.entityId = entityId;
  };

  const ListItem = ({ doc }) => (<div className=''>
    <span className="block font-semibold text-gray-700">{doc.data?.name}</span>
    <span className="text-sm inline text-sky-600">{doc.data?.nameSlug}</span>
    <span className="text-sm inline text-gray-400 ml-2">[ {doc.data?.type} ]</span>
    {doc.data?.forMainModerator ? (
      <span className="text-sm inline text-purple-400 ml-2">[ moderadores ]</span>
    ) : null}
    {doc.data?.forOwner ? (
      <span className="text-sm inline text-purple-400 ml-2">[ owners ]</span>
    ) : null}
  </div>);

  const fetchItems = async ({ setDocs }) => {
    let docs = await TaxonomyType.findByEntityId(entityId);
    console.log('fetchs', docs)
    docs = docs.filter((doc) => doc.data?.deleted !== true);
    setDocs(docs);
    setTaxonomyTypesOfEntity(docs);
  };

  return (
    <div className="">
      <SectionCrudModel
        model={TaxonomyType}
        entitySlug={'taxonomyTypes'}
        editStyle="modal"
        dataMode="all"
        listStyle="dragable"
        reorder={true}
        title="Atributos"

        // callbacks 
        fetchItems={fetchItems}
        handleBeforeSave={handleBeforeSave}

        // add UI
        ListItem={ListItem}
        FormSection={(props) => <TaxonomyTypeForm {...props} entityDoc={entityDoc} entitySlug={entitySlug} taxonomyTypesOfEntity={taxonomyTypesOfEntity} />}
        // ListBtns={}
      />
    </div>
  );
}

export function RouteEntityCreatorTaxonomy() {
  const { entityId } = useParams();

  return (
    <LayoutAdmin>
      {/* path */}
      <div className="flex mb-4">
        <Link to="/a"><h2 className="text-xl font-thin mr-3">Admin</h2></Link>
        <Link to={`/a/config/entity-creator/${entityId}/form`}><h2 className="text-xl font-thin mr-3">Entidad</h2></Link>
        <h2 className="text-xl font-semibold">Atributos</h2>
      </div>

      <Content entityId={entityId}></Content>
    </LayoutAdmin>
  );
}