import { useEffect, useMemo, useRef, useState } from "react";
import _ from 'lodash';
import SectionCrudModel from "../../components/SectionCrudModel";
import Model from "../../libs/ModelClass";
import { EntityTaxonomyForm, inputClasses } from "../../components/EntityTaxonomyForm";
import { useListDoc } from "../entity/docCardsVariants";
import { usePanel } from "../panel/usePanel";
import { getPrefix, withPrefix } from "../instance/utilsInstance";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { usePrevious } from "../../libs/usePrevious";
import toast from "react-hot-toast";
import config, { getConfig } from "../../config";
import { IonButtons, IonCard, IonTitle } from "@ionic/react";
import { useAsyncMemo } from "use-async-memo";
import { objectFormToTaxonomyTypes } from "../entity/ObjectForm";
import { EntityDocListView } from "../../components/EntityDocView";
import { useEntityFullBySlug } from "../entity/Entity";
import { MaxCountLimitAlert, useCanAddMoreDocs } from "../entity/MaxCountLimitAlert";
import { useForm } from "react-final-form";
import { nanoid } from "nanoid";
import { useFormContext } from "../entity/RouteEntityCrudForm";
import { stackClasses } from "../../libs/utils";
import { vatTypesFields } from "../sales/dataTypeInvoiceTaxes";


const mandatoryVariantsFields = {
  variantInvoiceDesc: {
    type: 'text',
    name: 'Descripción para facturas',
    descriptionText: 'Específica de esta variable'
  },
  variantVatType: {
    type: 'select',
    name: 'Tipo de IVA',
    descriptionText: 'Específica de esta variable',
    options: _.map(vatTypesFields, ({ name }, key) => ({ label: name, value: key })),
    design: 'buttons'
  },
  variantImgs: {
    type: 'gallery',
    name: 'Fotos',
    doResize: true,
  }
};

const variantsFieldsNamesForForm = ['variantImgs'];

// export const syncVariantDocsWithMain = async () => {
//   const docs = await ItemMainModel.getAll();
//   for (const mainDoc of docs) { 
//     const oldDocs = await ItemVariantsModel.filterByAttributes({
//       mainItemId: mainDoc.id
//     });
//     await ItemVariantsModel.updateMany(oldDocs, {
//       name: mainDoc?.data?.name,
//       price: mainDoc?.data?.price,
//       mainAvailable: mainDoc?.data?.mainAvailable,
//       itemType: mainDoc?.data?.itemType,
//       itemCategory: mainDoc?.data?.itemCategory,
//       mainImgs: null
//     });
//     console.log('updated', mainDoc?.data?.name, mainDoc?.data?.price, oldDocs?.length)
//   }
// };

// export const syncVariantDocsSizes = async () => {
//   const docs = await ItemMainModel.getAll();
//   let i = 0;
//   for (const mainDoc of docs) { 
//     const oldDocs = await ItemVariantsModel.filterByAttributes({
//       mainItemId: mainDoc.id
//     });
//     if (oldDocs?.length) {
//       for (const doc of oldDocs) {
//         if (doc.data.sizes?.xs) {
//           delete doc.data.sizes.xs;
//           doc.data.sizes.xp = true;
//           console.log(i++, 'updated xs => xp')
//         }
//         if (doc.data.sizes?.s) {
//           delete doc.data.sizes.s;
//           doc.data.sizes.p = true;
//           console.log(i++, 'updated s => p')
//         }
//         if (doc.data.sizes?.l) {
//           delete doc.data.sizes.l;
//           doc.data.sizes.g = true;
//           console.log(i++, 'updated l => g')
//         }
//         if (doc.data.sizes?.xl) {
//           delete doc.data.sizes.xl;
//           doc.data.sizes.xg = true;
//           console.log(i++, 'updated xl => xg')
//         }
//         await doc.save();
//       }
//     }
//   }
// };

export const updateVariantDocs = async ({ doc, isEdit, entitySlug, taxonomyTypesDocList }, formValues) => {
  const mainItemDoc = doc;
  let {
    // tmp
    tmp_itemCategoryDoc,
    tmp_categoryFieldsDocs,
    // to save
    ...mainData
  } = formValues;
  let instanceHash = getPrefix(entitySlug);
  const ItemVariantsModel = Model.extend(withPrefix(instanceHash, config.modules.cart.cartItemVariantsEntitySlug));
  // apply main name
  if ((!formValues['name'] || formValues['name'] === ' ') && formValues['itemCategory']) {
    formValues['name'] = tmp_itemCategoryDoc?.data?.nameSingle || tmp_itemCategoryDoc?.data?.name || null;
  }
  // update variants
  if (isEdit && mainItemDoc?.id) {
    // extract main values
    const modifiers = {};
    taxonomyTypesDocList.forEach(taxonomyType => {
      // omit description
      if (taxonomyType.data.nameSlug === 'description') {
        return;
      }
      modifiers[taxonomyType.data.nameSlug] = formValues[taxonomyType.data.nameSlug];
    });
    modifiers['itemCategory_fieldsIds'] = formValues['itemCategory_fieldsIds'];
    const oldDocs = await ItemVariantsModel.filterByAttributes({
      mainItemId: mainItemDoc.id
    });
    await ItemVariantsModel.updateMany(oldDocs, modifiers);
  }
  // clean main data from category fields of first variant
  else {
    tmp_categoryFieldsDocs?.forEach(taxonomyType => {
      Reflect.deleteProperty(mainData, taxonomyType.data.nameSlug);
    });
  }
  // data to save
  return mainData;
};

export const createFirstVariant = async ({ doc, formValues, modifiedFormValues, instanceDoc, isEdit, entitySlug, taxonomyTypesDocList }, savedDoc) => {
  const mainItemDoc = savedDoc;
  let { tmp_itemCategoryDoc, tmp_categoryFieldsDocs } = formValues;
  let instanceHash = instanceDoc.data.hash;
  // crear primera variante
  if (!isEdit && mainItemDoc?.id) {
    // extract main values
    const modifiers = {};
    const copyData = taxonomyType => {
      // omit description
      if (taxonomyType.data.nameSlug === 'description') {
        return;
      }
      modifiers[taxonomyType.data.nameSlug] = formValues[taxonomyType.data.nameSlug];
    };
    taxonomyTypesDocList.forEach(copyData);
    tmp_categoryFieldsDocs?.forEach(copyData);
    // set main id and save
    modifiers.mainItemId = mainItemDoc.id;
    modifiers.variantAvailable = formValues.mainAvailable;
    modifiers.variantImgs = formValues.variantImgs;
    modifiers['itemCategory_fieldsIds'] = formValues['itemCategory_fieldsIds'];
    const ItemVariantsModel = Model.extend(withPrefix(instanceHash, config.modules.cart.cartItemVariantsEntitySlug));
    await ItemVariantsModel.create(modifiers);
    // clean mainDoc
    // remove categoryFields data from main
    tmp_categoryFieldsDocs?.forEach(taxonomyType => {
      Reflect.deleteProperty(mainItemDoc.data, taxonomyType.data.nameSlug);
    });
    await mainItemDoc.save();
  }
};

export const deleteVariantDocs = async ({ doc, entitySlug }) => {
  let instanceHash = getPrefix(entitySlug);
  const ItemVariantsModel = Model.extend(withPrefix(instanceHash, config.modules.cart.cartItemVariantsEntitySlug));
  const mainItemDoc = doc;
  const modifiers = {
    deleted: true
  };
  const oldDocs = await ItemVariantsModel.filterByAttributes({
    mainItemId: mainItemDoc.id
  });
  await ItemVariantsModel.updateMany(oldDocs, modifiers);
};

export const VariantsCRUDList = (props) => {
  const {
    instance,
    entityMap,
    mainItemDoc,
    mainTaxonomyTypesDocsList,
    isAllowed,
    itemCategoryDoc,
    categoryFieldsDocs
  } = props;
  const variantsEntitySlug = entityMap.cartItemVariants.entitySlug;
  const ItemVariantsModel = Model.extend(entityMap.cartItemVariants.entitySlug);
  const ItemMainModel = Model.extend(entityMap.cartItems.entitySlug);
  const { selectedInstance } = usePanel();
  const [ refresh, setRefresh ] = useState(0);
  const canAddMoreDocs = useCanAddMoreDocs(variantsEntitySlug, {
    queryToCountForLimits: { 
      mainItemId: mainItemDoc.id,
      variantAvailable: 'true',
      deleted: 'false'
    },
    fieldForCountLimit: 'maxItemsVariants'
  }, selectedInstance, refresh);

  const [fieldsRequired, setFieldsRequired] = useState([]);
  const variantsEntitySpecs = useEntityFullBySlug({ entitySlug: variantsEntitySlug });

  const variantsTaxonomyTypes = useMemo(() => {
    let taxonomyTypesDocList = [];
    // prevent render main taxonomy types on variant list
    variantsEntitySpecs?.taxonomyTypesDocList?.forEach((variantTaxonomyTypeDoc) => {
      const isMainTaxonomyType = mainTaxonomyTypesDocsList.find((mainTaxonomyTypeDoc) => {
        return mainTaxonomyTypeDoc.data.nameSlug === variantTaxonomyTypeDoc.data.nameSlug;
      });
      if (!isMainTaxonomyType) {
        taxonomyTypesDocList.push(variantTaxonomyTypeDoc);
      }
    });
    // add categoryFieldsDocs
    categoryFieldsDocs?.forEach((taxonomyType) => {
      // exclude image field
      if (taxonomyType.data.nameSlug === 'variantImgs') { return; }
      taxonomyTypesDocList.push(taxonomyType);
    });
    return taxonomyTypesDocList;
  }, [variantsEntitySpecs, mainTaxonomyTypesDocsList, categoryFieldsDocs]);

  // set required fields to validate of categoryFields
  useEffect(() => {
    let requiredFields = [];
    categoryFieldsDocs?.forEach((taxonomyType) => {
      if (taxonomyType.data.required && !fieldsRequired.includes(taxonomyType.data.nameSlug)) {
        requiredFields.push(taxonomyType.data.nameSlug);
      }
    });
    if (requiredFields.length) {
      requiredFields = _.union(fieldsRequired, requiredFields);
      setFieldsRequired(requiredFields);
    }
  }, [variantsTaxonomyTypes, categoryFieldsDocs]);

  const onValidation = (values, errors) => {
    // all required fields
    fieldsRequired.forEach(field => {
      if (!values[field]) {
        errors[field] = ' '; 
      }
    });
  };

  const fetchItems = async ({ setDocs }) => {
    if (!mainItemDoc.id) {
      setDocs([]);
      return;
    }
    let docs = await ItemVariantsModel.filterByAttributes({ mainItemId: mainItemDoc.id, deleted: 'false' });
    setDocs(docs);
    setRefresh(refresh + 1);
  };

  const handleBeforeSave = async (doc) => {
    if (mainItemDoc.id && mainTaxonomyTypesDocsList?.length) {
      doc.mainItemId = mainItemDoc.id;
      doc['itemCategory_fieldsIds'] = itemCategoryDoc?.data.fieldsIds;
      const refreshMainItemDoc = await ItemMainModel.findById(mainItemDoc.id);
      // assing main attr values
      mainTaxonomyTypesDocsList.forEach(taxonomyType => {
        if (taxonomyType.data) {
          doc[taxonomyType.data.nameSlug] = refreshMainItemDoc?.data[taxonomyType.data.nameSlug];
        }
      });
    }
  };
  
  const ListItem = useListDoc({
    entitySpecs: variantsEntitySpecs,
    isAllowed,
    // outstandingOnly: true,
    ExtraFields: ({ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug, classes }) => (<>
      {/* 
        show data of categoryFieldsDocs
      */}
      {categoryFieldsDocs ? (
        <EntityDocListView
          doc={doc}
          instance={instance}
          entitySlug={entitySlug}
          taxonomyTypesDocList={categoryFieldsDocs} // sólo estos variantsFieldsNamesForList deben mostrarse
          outstandingOnly={false}
          viewType="show"
          classes={classes}
          render={({ doc, ViewData, mainAttr, mainImgAttr, classes }) => (
            categoryFieldsDocs?.map((taxonomyType) => (
              taxonomyType.data.nameSlug !== 'variantImgs' ? (
                <ViewData key={taxonomyType.data.nameSlug} field={taxonomyType.data.nameSlug} classes={{ fieldContainer: `text-xs font-normal` }} />
              ) : null
            ))
          )}
        />
      ) : null}
    </>),
    classes: {
      img: '!h-[105px] !w-[105px]'
    }
  });

  const ExtraActions = () => (
    <IonButtons slot="start">
      <MaxCountLimitAlert canAddMoreDocs={canAddMoreDocs} actionToDo="ocultar o eliminar" />
    </IonButtons>
  );

  // show form
  const FormInputFields = (({ values }) => (<>
    <EntityTaxonomyForm
      instance={instance}
      entitySlug={variantsEntitySlug}
      fieldsRequired={fieldsRequired}
      taxonomyTypesDocList={variantsTaxonomyTypes}
      values={values}
      classes={inputClasses}
    />
  </>));

  return (<>
    {mainItemDoc?.id ? (
      <SectionCrudModel
        model={ItemVariantsModel}
        entitySlug={variantsEntitySlug}
        editStyle="modal"
        listStyle="list"
        // validatiion
        fieldsRequired={fieldsRequired}
        onValidation={onValidation}
        // callbacks 
        fetchItems={fetchItems}
        handleBeforeSave={handleBeforeSave}
        // show
        showBtnAdd={canAddMoreDocs?.canAddMoreDocs}
        // UI
        ListItem={ListItem}
        FormInputFields={FormInputFields}
        ExtraActions={ExtraActions} 
      />
    ) : null}
  </>);
};

// VariantsCRUD
const fetchCategoryFieldsData = async (itemCategoryId, entityMap, instanceHash) => {
  if (!itemCategoryId || !itemCategoryId.length) return { itemCategoryDoc: null, categoryFieldsDocs: null };

  const CategoryModel = Model.extend(entityMap.cartItemCategories.entitySlug);
  const itemCategoryDoc = await CategoryModel.findById(itemCategoryId);

  const FieldModel = Model.extend(
    entityMap.cartItemFields?.entitySlug ||
    withPrefix(instanceHash, getConfig().modules.cart.cartItemFieldsEntitySlug)
  );

  let fieldsDocsIds = itemCategoryDoc.data.fieldsIds || [];
  if (_.isString(fieldsDocsIds)) fieldsDocsIds = [fieldsDocsIds];

  let fieldsDocs = await FieldModel.where('id', 'in', fieldsDocsIds);
  fieldsDocs = _.map(fieldsDocsIds, (id) => fieldsDocs.find(doc => doc.id === id));

  const mandatoryTaxonomyTypesDocs = objectFormToTaxonomyTypes(mandatoryVariantsFields)?.map(doc => ({ id: doc.id, data: doc }));

  const categoryFieldsDocs = [
    ...mandatoryTaxonomyTypesDocs,
    ...fieldsDocs.flatMap(doc => doc?.data.fields?.map(field => ({ id: field.id, data: field })))
  ];

  return { itemCategoryDoc, categoryFieldsDocs, fieldsDocsIds };
};

export const VariantsCRUD = (props) => {
  const { values, classes, docId, entitySlug, isAllowed, taxonomyTypesDocList, userAuth } = props;
  const { fieldsRequired, setFieldsRequiredMap } = useFormContext();
  const elementId = useRef(nanoid());
  const formApi = useForm();
  const { settings } = usePanel();
  const instanceHash = getPrefix(entitySlug) || 'main';
  const isSettingsLoaded = settings.loadScope(instanceHash);
  const entityMap = settings.get(instanceHash, 'modules.cart')?.entities;
  const mainItemDocData = values ? { id: values.id, data: values } : props.doc;
  const listRef = useRef(null);
  const isNew = docId === 'new';

  console.log('fieldsRequired', fieldsRequired)
  console.log('VariantsCRUD entityMap', entityMap)

  const itemCategoryId = values.itemCategoryId;

  const [itemCategoryDocSource, setItemCategoryDocSource] = useState(null);
  const [itemCategoryDoc, setItemCategoryDoc] = useState(null);
  const [categoryFieldsDocs, setCategoryFieldsDocs] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { itemCategoryDoc, categoryFieldsDocs, fieldsDocsIds } = await fetchCategoryFieldsData(itemCategoryId, entityMap, instanceHash);
      setItemCategoryDocSource(itemCategoryDoc);
      setCategoryFieldsDocs(categoryFieldsDocs);
      formApi.change('itemCategory_fieldsIds', fieldsDocsIds);
      formApi.change('tmp_itemCategoryDoc', itemCategoryDoc);
      formApi.change('tmp_categoryFieldsDocs', categoryFieldsDocs);
    };

    if (itemCategoryId) {
      fetchData();
    }
  }, [itemCategoryId]);

  useEffect(() => {
    if (itemCategoryDocSource?.id) {
      return itemCategoryDocSource.onSnapshot(async (updatedDoc) => {
        const { itemCategoryDoc, categoryFieldsDocs, fieldsDocsIds } = await fetchCategoryFieldsData(itemCategoryId, entityMap, instanceHash);
        setItemCategoryDoc(itemCategoryDoc);
        setCategoryFieldsDocs(categoryFieldsDocs);
        formApi.change('tmp_itemCategoryDoc', itemCategoryDoc);
        formApi.change('itemCategory_fieldsIds', fieldsDocsIds);
        formApi.change('tmp_categoryFieldsDocs', categoryFieldsDocs);
      });
    }
  }, [itemCategoryDocSource?.id]);
  
  const assignFieldsRequired = (fieldsDocs) => {
    const requiredFields = fieldsDocs.filter(doc => doc.data.required).map(doc => doc.data.nameSlug);
    if (requiredFields.length) {
      setFieldsRequiredMap(prev => ({ ...prev, [elementId.current]: requiredFields }));
    }
  };

  useEffect(() => {
    if (isNew && categoryFieldsDocs) {
      assignFieldsRequired(categoryFieldsDocs);
    } else {
      setFieldsRequiredMap(prev => {
        delete prev[elementId.current];
        return prev;
      });
    }
  }, [itemCategoryDoc, categoryFieldsDocs, docId]);

  useEffect(() => {
    return () => {
      setFieldsRequiredMap(prev => {
        delete prev[elementId.current];
        return prev;
      });
    };
  }, []);

  if (!isSettingsLoaded || !entityMap || (itemCategoryId && !categoryFieldsDocs)) {
    return <div className="py-12 flex place-content-center font-brand-main"><BadgeLoading className="text-brand-dark" /></div>;
  }

  return (
    <div ref={listRef} className={classes?.fieldContainer}>
      <div className={classes?.fieldInput}>
        {!mainItemDocData?.id || !taxonomyTypesDocList ? (
          categoryFieldsDocs?.length ? (
            <EntityTaxonomyForm
              instance={instanceHash}
              entitySlug={entitySlug}
              taxonomyTypesDocList={
categoryFieldsDocs?.filter(field => {
                    const mandatoryKeys = Object.keys(mandatoryVariantsFields);
                    const excludedKeys = _.difference(mandatoryKeys, variantsFieldsNamesForForm);
                    return !excludedKeys.includes(field.data.nameSlug)
;
                  })}
              values={values}
              fieldsRequired={fieldsRequired}
              isAllowed={isAllowed}
              userAuth={userAuth}
              classes={stackClasses(classes?.inputClasses || {}, inputClasses)}
            />
          ) : (
            <div>
              <div className={(classes?.inputClasses || inputClasses)?.fieldLabel}>Atributos</div>
              <div>Seleccione una categoría para ver los atributos</div>
            </div>
          )
        ) : (
          <>
            <div>
              <IonTitle size="small" color="dark" className="px-0 text-xs uppercase font-bold">Variantes del Item</IonTitle>
              <IonTitle size="small" color="medium" className="px-0 text-sm">Grupos de cualidades</IonTitle>
            </div>
            <IonCard className="mx-0 my-0 mt-2">
              <VariantsCRUDList
                instance={instanceHash}
                entityMap={entityMap}
                mainItemDoc={mainItemDocData}
                mainTaxonomyTypesDocsList={taxonomyTypesDocList}
                isAllowed={isAllowed}
                itemCategoryDoc={itemCategoryDoc}
                categoryFieldsDocs={categoryFieldsDocs}
              />
            </IonCard>
          </>
        )}
      </div>
    </div>
  );
};
